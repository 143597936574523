import React from 'react';
import { Link } from 'react-router-dom';
import { isReturnStatement } from 'typescript';
import CartWidget from '../store/cart/cart-widget';
import UserWidget from '../users/user-widget';
import AdminTopBar from './top-admin-bar';

const AppHeader = (props: any) => {
  return (
    <>
      <header className="section-header">
        <AdminTopBar/>
        <section className="header-main border-bottom">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-2 col-6">
                <Link to="/store" className="brand-wrap">
                  <div className="d-flex flex-row">
                    <div>
                      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-bag-check" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M10.854 8.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                        <path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1zm3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4h-3.5zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V5z" />
                      </svg>
                    </div>
                    <div>
                      <div style={{ fontSize: '20px', paddingTop:'5px', paddingLeft:'5px' }} className="font-weight-bold">
                        QuickShop
                      </div>
                    </div>
                  </div>
                  {/* <img className="logo" src="/assets/theme/fresh-blue/images/logo.png" /> */}
                </Link> {/* brand-wrap.// */}
              </div>
              <div className="col-lg-6 col-12 col-sm-12">
                <div className="input-group w-100">
                  <input type="text" className="form-control" placeholder="Search" />
                  <div className="input-group-append">
                    <button className="btn btn-primary" type="submit">
                      <i className="fa fa-search" /> Search
                      </button>
                  </div>
                </div>

              </div> {/* col.// */}
              <div className="col-lg-4 col-sm-6 col-12 pt-3 pt-md-0">
                <div className="widgets-wrap float-lg-right">
                  <CartWidget />
                  <UserWidget />
                </div> {/* widgets-wrap.// */}
              </div> {/* col.// */}
            </div> {/* row.// */}
          </div> {/* container.// */}
        </section> {/* header-main .// */}
      </header> {/* section-header.// */}
    </>
  );
}

export default AppHeader;