import React from 'react';
import { ProductDetailsView } from '../../models/api-dto';
import * as urls from '../../models/app-url-consts';
import * as pdb from '../../data-services/product-details-data';
import Swal from 'sweetalert2';


type attributes = {
  product: any,
  index: number,

  editProduct(pv: ProductDetailsView): void,
  productDeleted() :void
}


const ProductListItem = (props: attributes) => {

  const prepareProductEdit = () => {
    pdb.GetProductDetails(props.product.ProductID).then(res => {
      props.editProduct(res.data);
    });
  }


  const deleteProductDetails = ()=>{
    Swal.fire({
      title: 'Delete Product',
      html: 'Are you sure want to delete <br/><b>' + props.product.ProductName + "</b>?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
      focusCancel:true,
    }).then((result) => {
      if (result.value) {
        pdb.DeleteProductDetails(props.product.ProductID).then(res=>{
          props.productDeleted();
        })
      } 
    });
  }


  const editLink = () => {
    return (<a onClick={prepareProductEdit} className="cursor-pointer title font-weight-bold">
      {props.product.ProductName}
      </a>);
  }

  const editButton = () => {
    return (<>
      <button className="btn btn-light"  title="Edit Product" onClick={prepareProductEdit}> 
        <i className="fa fa-pencil-alt" /> 
      </button>
    </>)
  }

  const deleteButton = () => {
    return (<>
      <button className="btn btn-light" title="Delete Product" onClick={deleteProductDetails}>  
        <i className="fa fa-times"></i> 
      </button>
    </>)
  }

  return <>
    <article className="card card-body mb-3" key={props.index + "_prd_key"} >
      <div className="row align-items-center">
        <div className="col-md-6">
          <figure className="itemside">
            <div className="aside"><img src={urls.product_images_url + props.product.Image} className="border img-sm" /></div>
            <figcaption className="info">
              <span className="text-muted">{props.product.Category}</span>
              {editLink()}
            </figcaption>
          </figure>
        </div>
        
        <div className="col">
          <span className="text-muted">MRP</span>
          <div className="price h5"> {props.product.MRP} </div>
        </div>
        <div className="col">
          <span className="text-muted">Sale Price</span>
          <div className="price h5"> {props.product.Price} </div>
        </div>
        <div className="col flex-grow-0 text-right">
          {editButton()}
        </div>
        <div className="col flex-grow-0 text-right">
          {deleteButton()}
        </div>
      </div>
    </article>
  </>;
}

export default ProductListItem;