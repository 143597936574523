import React from 'react';

const UserPasswordChange = (props:any)=> {
    return (<>
        <div className="card">
          <article className="card-body">
            <header className="mb-4">
              <h4 className="card-title">Change Password</h4>
            </header>
            <form className="block-register">
              <div className="form-group form-row">
                <label className="col-md-3 col-form-label">Username</label>
                <div className="col-md-4">
                  <input type="text" className="form-control" />
                </div>

              </div>
              <div className="form-group form-row">
                <label className="col-md-3 col-form-label">Password</label>
                <div className="col-md-4">
                  <input className="form-control" type="password" placeholder="Type your new password here..." />
                </div>
              </div>
              
              <div className="form-row mb-2">
                <div className="col-sm-9 offset-sm-3">
                  <button type="submit" className="btn btn-primary">Update</button>
                </div>
              </div>
            </form>
          </article> {/* card-body end .// */}
        </div>
    
      </>)
}

export default UserPasswordChange;