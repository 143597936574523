import { useEffect, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import * as sdb from '../../data-services/sales-data';
import { SaleOrderLineItemView, SaleOrderView } from '../../models/api-dto';
import OrderStatusBadge from './order-status-badge';
import OrderCustomerView from './order-customer-view';
import OrdersAdminView from './orders-admin-view';
import { connect, useDispatch } from 'react-redux';
import { mapStateToProps } from '../../redux-infra/rdx-state-mapper';
import * as rdxConsts from '../../redux-infra/rdx-constants';
import * as actions from '../../redux-infra/rdx-actions';


const Orders = (props: any) => {

    const [orders, setOrders] = useState([]);
    const [showDetails, setShowDetails] = useState(false);
    const [saleItems, setSaleItems] = useState<SaleOrderLineItemView[]>([])
    const [saleOrder, setSaleOrder] = useState<SaleOrderView>(new SaleOrderView())

    const dispatch = useDispatch();

    useEffect(() => {
        //console.log(orders);
        loadAllOrders();
        setShowDetails(false);
    }, [])


    useEffect(()=>{
        //console.log('signal cancel');
        if(props[rdxConsts.APP_ORDER_CANCEL] > 0)
            orderCanceled(props[rdxConsts.APP_ORDER_CANCEL]);
    },[props[rdxConsts.APP_ORDER_CANCEL]])

    const loadAllOrders = () =>{
        sdb.GetAllPendingSaleOrders().then(res => {
            setOrders(res.data);
        })
    }

    const orderCanceled = (soid: number)=> {
        sdb.CancelSaleOrder(soid).then(res=>{
            //console.log('canceled');
            openOrderDetails(soid);
            dispatch(actions.actionCancelOrder(0));
        })
    }

    const openOrderDetails = (soid: number) => {
        sdb.GetSaleOrderView(soid).then(so=>{
            //console.log('opening so');
            setSaleOrder(so.data);
            sdb.GetSaleOrderItems(soid).then(res=>{
                console.log('got so items');
                setSaleItems(res.data);
                setShowDetails(true);
            });
        })
    }

    const orderDetailViewClosed = ()=> {
        setShowDetails(false);
        loadAllOrders();
    }

    const orderRow = () => {
        return orders.map((o: any, idx: number) => {
            return (<tr key={"ordw"+idx}>
                <td>
                    <p className="title mb-0">{o.OrderNumber}</p>
                    <var className="price">Rs. {o.Amount}</var>
                </td>
                <td> <OrderStatusBadge status={o.Status} />  <br /> Items: {o.Items} </td>
                <td style={{ width: 270 }} className="text-right">
                    {/* <button className="btn btn-outline-primary mr-1" onClick={()=>{}}> Track Order </button> */}
                    <button className="btn btn-outline-primary" onClick={()=>openOrderDetails(o.ID)}> Order Details </button>
                </td>
            </tr>)
        });
    }


    return (
        <>
        {showDetails && 
            
            <><OrdersAdminView close={orderDetailViewClosed} items={saleItems} saleOrder={saleOrder} />
            {/* <OrderCustomerView close={()=>setShowDetails(false)} items={saleItems} saleOrder={saleOrder} /> */}
            </>
        }
        
        {!showDetails &&
            <article className="card">
                <header className="card-header border-bottom-0 bg">
                    <strong className="d-inline-block mr-3 h4 mb-0">All Orders</strong>
                    <span>Total: {orders.length}</span>
                </header>

                <div className="table-responsive">
                    <table className="table table-hover">
                        <tbody>{orderRow()}</tbody>
                    </table>
                </div>
            </article>
        }
        </>
    );
}

export default connect (mapStateToProps) (Orders);