import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { mapStateToProps } from "../redux-infra/rdx-state-mapper";
import * as utils from '../models/app-utils';
import * as consts from '../models/app-consts';
import * as rdxConsts from '../redux-infra/rdx-constants';
import { QL_User } from "../models/api-dto";
import * as AppAct from '../redux-infra/rdx-actions';

const UserWidget = (props: any) => {

    const [user, setUser] = useState<QL_User>(new QL_User());
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(()=>{
        GetUserInfo();        
    },[])

    useEffect(()=>{
        GetUserInfo();
    },[props[rdxConsts.APP_USER_STATUS]])

    const GetUserInfo = ()=> {
        let userstr = utils.cacheGet(consts.USER_STORE_KEY);
        if(userstr){
            let usr = JSON.parse(userstr);
            if(usr.AID > 0)
                setUser(usr);
            else {
                cleanUserFromCache();
            }
        } else {
            cleanUserFromCache();
        }
    }

    const cleanUserFromCache = () => {
        setUser(new QL_User());
        dispatch(AppAct.actionUserStatus(-1));
        dispatch(AppAct.actionCartStatus(-1));
        utils.RemoveUserCache();
        history.push('/store');
    }

    const logout = ()=> {
        utils.cacheSetUser(new QL_User());
        GetUserInfo();
    }

    return (<>

        <div className="widget-header icontext">
            {/* <a className="icon icon-sm rounded-circle border"><i className="fa fa-user" /></a> */}
            {user.AID === 0 && 
            <div className="text">
                <span className="text-muted font-weight-bold">Welcome!</span>
                <div>
                    <Link to={"/login"}> Log in</Link> |
                    <Link to={"/signup"}> Register</Link>
                </div>
            </div>
            }

            {user.AID > 0 && 
            <div className="text">
                <span className="text-black font-weight-bold">{user.ShowName}</span>
                <div>
                    <a onClick={logout} style={{fontSize:'13px'}} className="text-primary font-weight-bold cursor-pointer">Log Out</a>
                </div>
            </div>
            }
        </div>
    </>);
}

export default connect(mapStateToProps)(UserWidget);