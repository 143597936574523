export const TYPE_PRODUCTS_LIST = 'TYPE_PRODUCTS_LIST';
export const APP_DATA_PRODUCTS = 'rdxProductsData';

export const TYPE_PRODUCTS_LIST_TITLE = 'TYPE_PRODUCTS_LIST_TITLE';
export const APP_DATA_PRODUCTS_TITLE = 'rdxProductsListTitle';

export const TYPE_PRODUCTS_CATEGORY_ID = 'TYPE_PRODUCTS_CATEGORY_ID';
export const APP_DATA_CATEGORY_ID = 'rdxProductsCategoryId';

export const TYPE_CART_STATUS = 'TYPE_CART_STATUS';
export const APP_CART_STATUS = 'rdxCartStatus';

export const TYPE_USER_STATUS = 'TYPE_USER_STATUS';
export const APP_USER_STATUS = 'rdxUserStatus';

export const TYPE_PRODUCT_DETAILS_CHANGE = 'TYPE_PRODUCT_DETAIL_CHANGE';
export const APP_PRODUCT_DETAILS = 'rdxProductDetails';

export const TYPE_ORDER_CANCEL = 'TYPE_ORDER_CANCEL';
export const APP_ORDER_CANCEL = 'rdxCancelOrder';
