import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as sdb from '../../data-services/sales-data';
import { ProductDetailsView, SaleOrderLineItemView, SaleOrderView } from "../../models/api-dto";
import { product_images_url } from '../../models/app-url-consts';
import OrderItemsView from "./order-items-view";
import OrderStatusTrackingButtons from "./order-status-buttons";
import OrderTrackingMap from "./order-tracking-map";

type attributes = {
  close(): void,
  items: SaleOrderLineItemView[]
  saleOrder: SaleOrderView,
}
const OrdersArchiveView = (props: attributes) => {

  return (<>

<div className="row">
            <div className="col-12 pb-3">
                <div className="d-flex flex-row justify-content-between">
                    <div className="align-self-center">
                        <h4 className="pb-0 mb-0">Sale Order</h4>
                    </div>
                    <div>
                        <button onClick={props.close} className="btn btn-outline-primary float-right">&lt; Back</button>
                    </div>
                </div>
            </div>
        </div>

    <div className="row">
      <main className="col-md-12">
        <article className="card">
          <header className="card-header">
            <OrderTrackingMap status={props.saleOrder.SaleOrder.OrderStatus} />
          </header>
          <OrderItemsView items={props.items} saleOrder={props.saleOrder} />
        </article>
      </main>
    </div>

  </>)
}

export default OrdersArchiveView;