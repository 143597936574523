import React, { useEffect, useState } from 'react';
import CartItemDetails from './cart/cart-item-details';
import * as utils from '../models/app-utils';
import * as cdb from '../data-services/cart-data';
import { CartView } from '../models/api-dto';
import CartAmount from './cart/cart-amount';
import { mapStateToProps } from '../redux-infra/rdx-state-mapper';
import * as rdxConsts from '../redux-infra/rdx-constants';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

const ShoppingCart = (props: any) => {

  const [cartItems, setCartItems] = useState<CartView[]>(new Array<CartView>());

  useEffect(() => {
    GetCartDetails();
  }, [props[rdxConsts.APP_CART_STATUS]])

  const GetCartDetails = () => {
    cdb.GetCart(utils.GetUserObj().AID).then(res => {
      //console.log(JSON.stringify(res.data,null,2));
        setCartItems(res.data);
    })
  }


  return (
    <>
      <section className="section-content padding-y bg">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3>Your Cart</h3>
            </div>
          </div>
          <div className="row">
            <main className="col-md-9">
              {cartItems && cartItems.map((c: CartView, idx: number) => {
                return (
                  <CartItemDetails item={c} key={"crt" + idx} />
                )
              })}

            </main> {/* col.// */}
            <aside className="col-md-3">
              {cartItems && <CartAmount items={cartItems} />}
            </aside> {/* col.// */}
          </div> {/* row.// */}
        </div>
      </section>
    </>
  );
}

export default connect(mapStateToProps)(ShoppingCart);