import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { CartView } from '../../models/api-dto';

type attributes = {
    items: CartView[]
}

const CartAmount = (props: attributes) => {

    const [subTotal, setSubTotal] = useState(0);
    const [grandTotal, setGrandTotal] = useState(0);
    const [shipping, setShipping] = useState(0);

    useEffect(() => {

        let subt = props.items.reduce((total: number, cart: CartView) =>
            total + (cart.CurrentPrice.SalePricePerUnit * cart.CartItem.Qty), 0
        )

        setSubTotal(subt);
        setGrandTotal(subt);

    }, [props.items]);


    return (<>
        <div className="card">
            <div className="card-body">
                {/* <div className="input-group mb-3">
          <input type="text" className="form-control" placeholder="Promo code" />
          <span className="input-group-append"> 
            <button className="btn btn-primary">Ok</button>
          </span>
        </div> */}
                <dl className="dlist-align">
                    <dt>Subtotal:</dt>
                    <dd className="text-right">Rs. {subTotal}</dd>
                </dl>
                <dl className="dlist-align">
                    <dt>Shipping:</dt>
                    <dd className="text-right text-danger">0</dd>
                </dl>
                <dl className="dlist-align">
                    <dt>Grand Total:</dt>
                    <dd className="text-right text-dark"><strong>Rs. {grandTotal}</strong></dd>
                </dl>
                <hr />
                <Link to={"/order-confirm"} className="btn btn-primary btn-block"> Purchase </Link>
                <p className="small my-3 text-muted">You may continue shopping to add more products before final check-out</p>
                <Link to={'/store'} className="btn btn-outline-warning btn-block">Continue Shopping</Link>
            </div> {/* card-body.// */}
        </div> {/* card.// */}
    </>);
}

export default CartAmount;