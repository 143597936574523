import { ML_ProductPrice, ProductDetailsView, QS_ProductDetail, QS_ProductImage } from "./api-dto";
import { ImageDTO } from "./app-dto";

export const createProductImage =() => {
    var p  = new ImageDTO();
    p.ProductImage = new QS_ProductImage();
    return p;
}

export const createProductDetailsView = ()=> {
    var p = new ProductDetailsView();
    p.details = new QS_ProductDetail();
    p.price = createProductPrice();
    return p;
}

export const createProductPrice = () => {
    var p = new ML_ProductPrice();
    p.MaxRetailPrice= 0;
    p.SalePricePerUnit = 0;
    p.LastUpdateDateTime = new Date();
    return p;
}