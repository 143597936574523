import  axios, { AxiosResponse } from 'axios';
import { isAsExpression } from 'typescript';
import { QL_User, UserVM } from '../models/api-dto';
import * as AppConsts from '../models/app-url-consts';

const api_url = AppConsts.base_api_url + "/api/users";

export const SaveUser = async (user : QL_User) => await axios.post(api_url + "/store",user)

export const SaveUserDetails = async (user : UserVM) => await axios.post(api_url + "/SaveUserDetails",user)

export const ChangePassword = async (uid: number, pass : string) => 
    await axios.post(api_url + "/ChangePassword",null,{params:{uid, pass}})

export const GetUserDetials = async (uid: number) => 
    await axios.get(api_url + "/GetUserDetails", {params : {userId : uid}})

export const Login = async (u : string, p : string) => 
    await axios.post(api_url + "/Login", null, {params : {uname : u, pass : p}})