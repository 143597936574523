import React, { useEffect, useState } from 'react';
import * as pdb from '../../data-services/product-details-data';
import ProductOneView from './product-one-view';
import { mapStateToProps } from '../../redux-infra/rdx-state-mapper';
import { connect } from 'react-redux';
import * as rdxConsts from '../../redux-infra/rdx-constants';

const ProductOneViewList = (props: any) => {

  const [products, setProducts] = useState([]);

  useEffect(()=>{
    loadProductsAll();
  },[])

  useEffect(()=>{
    loadProductsByCategory();
  },[props[rdxConsts.APP_DATA_CATEGORY_ID]])

  const loadProductsAll = () => {
    pdb.GetProductDetailsList().then(res => {
      setProducts(res.data);
    })
  }

  const loadProductsByCategory = ()=> {
    let cat_id = props[rdxConsts.APP_DATA_CATEGORY_ID];
    
    if (cat_id === 0 || !cat_id)
      loadProductsAll();
    else
      pdb.GetProductDetailsListByCategoryId(cat_id).then(res => {
        setProducts(res.data);
      });
  }

return (<section className="section-content">
    <div className="container">
      <header className="section-heading">
        <h3 className="section-title">{props[rdxConsts.APP_DATA_PRODUCTS_TITLE]}</h3>
      </header>
      <div className="row">
        {products?.length > 0 &&
          products.map((x: any, idx: number) =>
            <ProductOneView key={"pdv" + idx} product={x} />)
        }
      </div>
    </div>
  </section>);
}

export default connect(mapStateToProps)(ProductOneViewList);
