import { useEffect, useState } from 'react';
import * as sdb from '../../data-services/sales-data';

type attributes = {
    status: number,
    orderId : number
}
const OrderStatusTrackingButtons = (props: attributes) => {

    const [orderStatus, setOrderStatus] = useState(props.status);

    useEffect(()=>{setOrderStatus(props.status)},[props.status])

    const updateOrderStatus = (stat:number)=> {
        sdb.UpdateOrderStatus(props.orderId, stat).then(res=>setOrderStatus(stat));
    }

    return (<>

        {orderStatus === 0 &&
            <button type="button" className="btn btn-danger">
                <i className="fa fa-times-circle"></i> Canceled
            </button>
        }

        {orderStatus >= 1 &&
            <div className="btn-group" role="group" aria-label="Filter by">
                <button onClick={()=>{updateOrderStatus(1)}} type="button" className={`btn btn-outline-gray ${orderStatus >= 1 ? 'active' : ''}`}>
                    <i className="fa fa-cart-arrow-down"></i> New Order
                </button>
                <button onClick={()=>{updateOrderStatus(2)}} type="button" className={`btn btn-outline-gray ${orderStatus >= 2 ? 'active' : ''}`}>
                    <i className="fa fa-check"></i> Accepted
                </button>
                <button onClick={()=>{updateOrderStatus(3)}} type="button" className={`btn btn-outline-gray ${orderStatus >= 3 ? 'active' : ''}`}>
                    <i className="fa fa-people-carry"></i> Packing
                </button>
                <button onClick={()=>{updateOrderStatus(4)}} type="button" className={`btn btn-outline-gray ${orderStatus >= 4 ? 'active' : ''}`}>
                    <i className="fa fa-shipping-fast"></i> Shipping
                </button>
                <button onClick={()=>{updateOrderStatus(5)}} type="button" className={`btn btn-outline-gray ${orderStatus >= 5 ? 'active' : ''}`}>
                    <i className="fa fa-thumbs-up"></i> Delivered
                </button>
            </div>
        }

    </>)

}

export default OrderStatusTrackingButtons;