import * as RdxConsts from './rdx-constants';
import * as rdxState from './rdx-state-mapper';

const RdxReducer = (state: any = {}, action: any) => {

    const finalizeState = (name:any, value: any) => {
        return {...state, [name]:value}
    }

    switch (action.type) {
        case RdxConsts.TYPE_PRODUCTS_CATEGORY_ID:
            return {...state, [RdxConsts.APP_DATA_CATEGORY_ID]:action.data };
            
        case RdxConsts.TYPE_PRODUCTS_LIST:
            //return finalizeState(RdxConsts.APP_DATA_PRODUCTS,action.data);    
        let s2 = { ...state, [RdxConsts.APP_DATA_PRODUCTS]: action.data };
            return s2;

        case RdxConsts.TYPE_PRODUCTS_LIST_TITLE:
            //return finalizeState(RdxConsts.APP_DATA_PRODUCTS_TITLE,action.data);    
        let s1 = { ...state, [RdxConsts.APP_DATA_PRODUCTS_TITLE]: action.data };
            return s1;

        case RdxConsts.TYPE_CART_STATUS:
            let sum = action.data;
            if (state[RdxConsts.APP_CART_STATUS])
                sum = +state[RdxConsts.APP_CART_STATUS] + action.data;
            return { ...state, [RdxConsts.APP_CART_STATUS]: sum };

        case RdxConsts.TYPE_USER_STATUS:
            return { ...state, [RdxConsts.APP_USER_STATUS]: action.data };

        case RdxConsts.TYPE_PRODUCT_DETAILS_CHANGE:
            return {...state, [RdxConsts.APP_PRODUCT_DETAILS] : action.data}

        case RdxConsts.TYPE_ORDER_CANCEL:
            return {...state, [RdxConsts.APP_ORDER_CANCEL] : action.data}

        default:
            return {};
    }

}

export default RdxReducer;