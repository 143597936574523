import React, { useEffect, useState } from 'react';
import * as pdb from '../../data-services/products-data';
import { QS_ProductImage } from '../../models/api-dto';
import { product_images_url } from '../../models/app-url-consts';

type attributes = {
    product_id : number
}

const ProductOneBigImageView = (props: attributes) => {

    const [bigImage, setBigImage] = useState('');
    const [Images, setImages] = useState<QS_ProductImage[]>()

    useEffect(()=>{
        pdb.GetProductImages(props.product_id).then(res=>{
            if(res.data.length > 0){
                setBigImage(res.data[0].FileName);
            }
            setImages(res.data);
        })
    },[])

    const showBig = (path : string)=> {
        setBigImage(path);
    }

    return (<>
        <article className="gallery-wrap">
            <div className="img-big-wrap">
            <a><img src={product_images_url + bigImage} /></a>
            </div>
            <div className="thumbs-wrap">
                {Images && Images.map((i:QS_ProductImage, idx:number)=>
                <div key={"small"+idx} onClick={()=>showBig(i.FileName)} className="item-thumb cursor-pointer"> <img src={product_images_url + i.FileName} /></div>)}
                
                {/* <a href="#" className="item-thumb"> <img src="/assets/theme/fresh-blue/images/items/12-2.jpg" /></a>
                <a href="#" className="item-thumb"> <img src="/assets/theme/fresh-blue/images/items/12.jpg" /></a>
                <a href="#" className="item-thumb"> <img src="/assets/theme/fresh-blue/images/items/4.jpg" /></a> */}
            </div>
        </article>
    </>)

}
export default ProductOneBigImageView;