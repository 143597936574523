import React from 'react';
import { Link } from 'react-router-dom';
import { CartView } from '../../models/api-dto';
import { product_images_url } from '../../models/app-url-consts';
import ItemQtyButton from './item-qty-button';
import ItemRemoveButton from './item-remove-button';

type attributes = {
    item : CartView
}

const CartItemDetails = (props: attributes) => {
    return (<>
    <article className="card card-body mb-3">
      <div className="row align-items-center">
        <div className="col-md-6">
          <figure className="itemside">
            <div className="aside"><img src={product_images_url + props.item.Image.FileName} className="border img-sm" /></div>
            <figcaption className="info">
              <span className="text-muted">{props.item.Product.Category.CategoryName}</span>
              <Link to={"/product/" + props.item.CartItem.ProductID} className="title"> 
              {props.item.Product.Product.ProductName}
              </Link>
              
            </figcaption>
          </figure> 
        </div> {/* col.// */}
        <div className="col"> 
        <ItemQtyButton qty={props.item.CartItem.Qty} productId={props.item.CartItem.ProductID} />
        </div> {/* col.// */}
        <div className="col">
        <div className="price-wrap"> 
			<var className="price h5">Rs. {props.item.CurrentPrice.SalePricePerUnit * props.item.CartItem.Qty}</var><br/>
			<small className="text-muted"> Rs. {props.item.CurrentPrice.SalePricePerUnit} each </small> 
		</div>
          
        </div>
        <ItemRemoveButton productId={props.item.CartItem.ProductID} />
        {/* <div className="col flex-grow-0 text-right">
          <a href="#" className="btn btn-light"> <i className="fa fa-times text-danger" /> </a>
        </div> */}
      </div> {/* row.// */}
    </article> {/* card .// */}
    </>);
}

export default CartItemDetails;