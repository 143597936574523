import React, { useEffect, useState } from 'react';
import { ML_ProductCategory } from '../../models/api-dto';
import ProductCategoryEdit from './product-category-edit';
import * as cdb from '../../data-services/product-details-data';

type attributes = {
    categoryId : number,
    allowCreateNew: boolean,
    //events
    onCategoryIdChanged:any,
}

const ProductCategoryDropdown = (props:attributes) => {

    const [catList, setCatList] = useState<ML_ProductCategory[]>();
    const [selCatId, setSelCatId] = useState(props.categoryId);
    const [editCatId, setEditCatId] = useState(props.categoryId);
    const [showModal, setShowModal] = useState(false);
    //const [selectCatObj, setSelectCatObj] = useState<ML_ProductCategory>(new ML_ProductCategory());

    useEffect(()=>{
        setSelCatId(props.categoryId);
        setEditCatId(props.categoryId);
    },[props.categoryId])
    

    useEffect(() => {
        loadAll();
    }, [])

    const loadAll = () => {
        cdb.GetAllCategory().then(res => {
            setCatList(res.data);
        });
    }

    const handleOnChange = (e: any) => {
        let val = +e.target.value;
        setSelCatId(val);
        setEditCatId(val);
        props.onCategoryIdChanged(val);
    }

    const handleAddNewClick = (e: any) => {
        setEditCatId(0);
        //setSelectCatObj({...new ML_ProductCategory()});
        setShowModal(true);
    }

    const handleEditClick = () => {
        //let c = catList?.find(x => x.PRCTID === selCatId) as ML_ProductCategory;
        //setSelectCatObj({...c});
        //setSelCatId(0);
        
        setEditCatId(selCatId);
        setShowModal(true);
    }

    const dataChanged = (savedCopy: ML_ProductCategory) => {
        loadAll();
        setSelCatId(savedCopy.PRCTID);
        props.onCategoryIdChanged(savedCopy.PRCTID);
        handleModalClose();
    }

    const handleModalClose = () => setShowModal(false);

    
    return (
        <>
            <div className="input-group">
                {catList && catList.length > 0 &&
                <select value={selCatId} className="form-control" onChange={handleOnChange}>
                    <option key="ct_0" value="0"></option>
                    {catList && catList.map((c: ML_ProductCategory, idx: number) => {
                        if (!c.IsActive)
                            return <option key={"ct" + idx} value={c.PRCTID}>{c.CategoryName} (Inactive)</option>
                        else
                            return <option key={"ct" + idx} value={c.PRCTID}>{c.CategoryName}</option>
                    })}
                </select>
                }
                <div className="input-group-append">
                    { selCatId > 0 &&
                        <button className="btn btn-outline-gray-light" onClick={handleEditClick}>Edit</button>
                    }
                    <button className="btn btn-primary" onClick={handleAddNewClick}>+Add New</button>
                </div>
            </div>

            <ProductCategoryEdit
                showModal={showModal}
                id={editCatId}
                onClose={handleModalClose}
                onSaved={dataChanged} />
        </>
    );

}

export default ProductCategoryDropdown;