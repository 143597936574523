import { QL_User } from "./api-dto";
import * as consts from './app-consts';

export const cacheSetUser = (user:QL_User)=> {
    localStorage.setItem(consts.USER_STORE_KEY, JSON.stringify(user));
}

export const cacheGet = (key : string) => {
    return localStorage.getItem(key)
}

export const GetUserObj = () : QL_User => {
    let str = localStorage.getItem(consts.USER_STORE_KEY);
    if(str){
        try{
            return JSON.parse(str);
        }catch(err){
        }
    }
    return new QL_User();
}

export const IsUserLoggedIn = ()=> {
    return GetUserObj().AID > 0;
}

export const RemoveUserCache = ()=>{
    localStorage.removeItem(consts.USER_STORE_KEY);
}

