import { ProductDetailsView } from '../models/api-dto';
import * as rdxConsts from './rdx-constants';

export const actionProductsList = (data:any) => { 
    return { type : rdxConsts.TYPE_PRODUCTS_LIST, data: data } 
}

export const actionProductsCategoryId = (data:number) => { 
    return { type : rdxConsts.TYPE_PRODUCTS_CATEGORY_ID, data: data } 
}

export const actionProductsListTitle = (title:string) => {
    return {type: rdxConsts.TYPE_PRODUCTS_LIST_TITLE, data:title}
}

export const actionCartStatus = (count : number) => {
    return {type: rdxConsts.TYPE_CART_STATUS, data:count}
}

export const actionUserStatus = (flag : number) => {
    return {type: rdxConsts.TYPE_USER_STATUS, data:flag}
}

export const actionUpdateProductDetail = (details : ProductDetailsView) => {
    return { type : rdxConsts.TYPE_PRODUCT_DETAILS_CHANGE, data: details}
}

export const actionCancelOrder = (orderId : number) => {
    return {type: rdxConsts.TYPE_ORDER_CANCEL, data:orderId}
}