import React, { useEffect, useState } from 'react';
import ProductAttributeAddButton from './product-details/product-attribute-add-button';
import ProductAttributesView from './product-details/product-attribute-view';
import ProductProsView from './product-details/product-pros-view';
import ProductProsAddButton from './product-details/product-pros-add-button';
import * as yup from 'yup';

import ProductDetailsEdit from './product-details/product-details-edit';
import { ProductDetailsView, QS_ProductParameter } from '../models/api-dto';
import { toast } from 'react-toastify';
import { ImageDTO } from '../models/app-dto';
import * as factory from '../models/object-factory';
import * as cdb from '../data-services/product-details-data';
import _ from 'lodash';
import { connect } from 'react-redux';
import { mapStateToProps } from '../redux-infra/rdx-state-mapper';
import * as rdxConsts from '../redux-infra/rdx-constants';

type attributes = {
  //productView : ProductDetailsView

  detailedUpdated(isReload:boolean) : void,
}

const ProductConfig = (props: any) => {
  const [obj, setObj] = useState<ProductDetailsView>(props[rdxConsts.APP_PRODUCT_DETAILS]);
  const [verr, setVErr] = useState<any>();
  const [attr, setAttr] = useState<QS_ProductParameter>(new QS_ProductParameter());
  const [productImages, setProductImages] = useState<ImageDTO[]>(new Array<ImageDTO>());
  const [saveImagesTrigger, setSaveImagesTrigger] = useState(false);

  let validator = yup.object({
    "Price": yup.object({
      "SalePricePerUnit": yup.number().moreThan(0, "Selling Price must be more than zero"),
      "MaxRetailPrice" : yup.number().moreThan(0, "MRP price must be more than zero")
    }),
    "Details": yup.object({
      "ProductID": yup.number().moreThan(0,"Select the Product "),
      "ProductDescription": yup.string().required("Describe your product"),
      "ProductCategoryID" : yup.number().moreThan(0,"Select the Product category")
    })
  });

  // useEffect(()=>{
  //   console.log(props[rdxConsts.APP_PRODUCT_DETAILS]);
  //   setObj(props[rdxConsts.APP_PRODUCT_DETAILS]);
  // },[props[rdxConsts.APP_PRODUCT_DETAILS]])

  const formSubmit = () => {

    validator.validate(obj).then((res: any) => {
      
      let copy = _.clone(obj);
      copy.Price.ProductID = copy.Details.ProductID;
      setObj(copy);

      setSaveImagesTrigger(!saveImagesTrigger);

      cdb.SaveProductDetails(copy.Details).then(res=>{
        copy.Details.PDID = res.data;
        cdb.SaveProductPrice(copy.Price).then(res=> {
          copy.Price.PSID = res.data;
          setObj(copy);
          toast.success("Product Details Saved!");
          props.detailedUpdated(true);
        }).catch(err => toast.error(err))
      });

      setVErr({});

    }).catch(err => {
      console.log("Error: " + JSON.stringify(err,null,2));
      setVErr(err);
      toast.error(err.errors[0]);
    });
  }

 
  const close =()=>{
    props.detailedUpdated(false);
  }

  const getInput = (obj: any) => {
    setVErr({});
    setObj(obj);
    //console.log(JSON.stringify(obj,null,2));
  }

  const prodImagesUpdated = (e: ImageDTO[]) => {
    //console.log("Prod det final "  + JSON.stringify(e,null,2));
    setProductImages(e);
  }

  return (
    <> {props[rdxConsts.APP_PRODUCT_DETAILS] &&
        <div className="card">
        <div className="card-body">
          <h4 className="card-title mb-4">Product Details</h4>

          <ProductDetailsEdit 
          saveImagesToggle={saveImagesTrigger} 
          errorObject={verr} 
          obj={props[rdxConsts.APP_PRODUCT_DETAILS]} 
          dataUpdated={getInput}></ProductDetailsEdit>


          <div className="form-row">
            <div className="col-12 text-center">
              <input type="button" onClick={formSubmit} className="btn btn-primary px-5 mr-3" value="Save" />
              <input type="button" className="btn btn-outline-gray" value="Cancel" onClick={close} />
            </div>
          </div>
        </div>
      </div>
       }
      {/* <article className="card mt-4">
        <div className="card-body">
          <div className="row">
            <aside className="col-md-6">
              <ProductAttributesView></ProductAttributesView>
              <ProductAttributeAddButton className="btn btn-outline-primary mt-3" attribute={attr}></ProductAttributeAddButton>
            </aside>

            <aside className="col-md-6">
              <ProductProsView></ProductProsView>
              <ProductProsAddButton className="btn btn-outline-primary mt-3" attribute={attr}></ProductProsAddButton>
            </aside>

          </div>
        </div>
      </article> */}
    </>
  );
}

export default connect(mapStateToProps)(ProductConfig);