import { useEffect, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import * as sdb from '../../data-services/sales-data';
import { SaleOrderLineItemView, SaleOrderView } from '../../models/api-dto';
import OrderStatusBadge from './order-status-badge';
import OrderCustomerView from './order-customer-view';
import OrdersAdminView from './orders-admin-view';
import OrdersArchiveView from './orders-archive-view';


const OrdersArchive = (props: any) => {

    const [orders, setOrders] = useState([]);
    const [showDetails, setShowDetails] = useState(false);
    const [saleItems, setSaleItems] = useState<SaleOrderLineItemView[]>([])
    const [saleOrder, setSaleOrder] = useState<SaleOrderView>(new SaleOrderView())

    useEffect(() => {
        loadAllOrders();
    }, [])


    const loadAllOrders = () =>{
        sdb.GetOrdersByStatus(5).then(completed => {
            sdb.GetOrdersByStatus(0).then(canceled=>{
                setOrders(completed.data.concat(canceled.data));
            })
        })
    }

    const openOrderDetails = (soid: number) => {
        sdb.GetSaleOrderView(soid).then(so=>{
            setSaleOrder(so.data);
            sdb.GetSaleOrderItems(soid).then(res=>{
                setSaleItems(res.data);
                setShowDetails(true);
            });
        })
    }

    const orderDetailViewClosed = ()=> {
        setShowDetails(false);
        loadAllOrders();
    }

    const orderRow = () => {
        return orders.map((o: any, idx: number) => {
            return (<tr key={"orarch"+idx}>
                <td>
                    <p className="title mb-0">{o.OrderNumber}</p>
                    <var className="price">Rs. {o.Amount}</var>
                </td>
                <td> <OrderStatusBadge status={o.Status} />  <br /> Items: {o.Items} </td>
                <td style={{ width: 270 }} className="text-right">
                    {/* <button className="btn btn-outline-primary mr-1" onClick={()=>{}}> Track Order </button> */}
                    <button className="btn btn-outline-primary" onClick={()=>openOrderDetails(o.ID)}> Order Details </button>
                </td>
            </tr>)
        });
    }


    return (
        <>
        {showDetails && 
            
            <><OrdersArchiveView close={orderDetailViewClosed} items={saleItems} saleOrder={saleOrder} />
            {/* <OrderCustomerView close={()=>setShowDetails(false)} items={saleItems} saleOrder={saleOrder} /> */}
            </>
        }
        
        {!showDetails &&
            <article className="card">
                <header className="card-header border-bottom-0 bg">
                    <strong className="d-inline-block mr-3 h4 mb-0">All Orders</strong>
                    <span>Total: {orders.length}</span>
                </header>

                <div className="table-responsive">
                    <table className="table table-hover">
                        <tbody>{orderRow()}</tbody>
                    </table>
                </div>
            </article>
        }
        </>
    );
}

export default OrdersArchive;