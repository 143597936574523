import React, { useEffect } from 'react';
import { ProductDetailsView, ProductView } from '../../models/api-dto';
import ProductListItem from './product-list-item';

type attributes = {
    products: any[],
    editProduct(pv: ProductDetailsView): void,
    productDeleted(): void
}

const ProductsListView = (props: attributes) => {
    return (
        <div className="row">
            <div className="col-12">
                <div className="d-flex flex-row justify-content-between align-items-center mb-3">
                    <div className="h4">All Products</div>
                    <div>
                        <button className="btn btn-primary" onClick={() => { props.editProduct(new ProductDetailsView()) }}>+ Add New</button>
                    </div>
                </div>

            </div>
            <div className="col-12">
                {props.products &&
                    props.products.map((p: any, idx: number) => {
                        return (
                            <ProductListItem productDeleted={props.productDeleted} key={idx + "pv"} product={p} index={idx} editProduct={props.editProduct} />)
                    })}
            </div>
        </div>
    );
}

export default ProductsListView;