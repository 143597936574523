import React from 'react';

const UserInfo = (props:any) => {
    return (<>
        <div className="card">
          <article className="card-body">
            <header className="mb-4">
              <h4 className="card-title">User Profile</h4>
            </header>
            <form className="block-register">
              <div className="form-group form-row">
                <label className="col-md-3 col-form-label">Full name</label>
                <div className="col">
                  <input type="text" className="form-control" placeholder="First name" />
                </div>
                <div className="col">
                  <input type="text" className="form-control" placeholder="Last name" />
                </div>
              </div>
              <div className="form-group form-row">
                <label className="col-md-3 col-form-label">Phone</label>
                <div className="col-9">
                  <input className="form-control" placeholder="Phone number" type="text" />
                </div>
              </div>
              <div className="form-group form-row">
                <label className="col-md-3 col-form-label">Email</label>
                <div className="col-sm-9">
                  <input type="email" className="form-control" placeholder="Ex. john@gmail.com" />
                </div>
              </div>
              <div className="form-row mb-2">
                <div className="col-sm-9 offset-sm-3">
                  <button type="submit" className="btn btn-primary">Update</button>
                </div>
              </div>
            </form>
          </article> {/* card-body end .// */}
          {/* <div className="card-footer text-center">Have an account? <a href="#">Log In</a></div> */}
        </div>
    
      </>)
}

export default UserInfo;