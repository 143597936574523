const OrderStatusBadge = (props: any) => {

    switch (props.status) {
        case 0:
            return <span className="badge badge-gray">Canceled</span>;
        case 1:
            return <span className="badge badge-danger">New Order</span>;
        case 2:
            return <span className="badge badge-info">Accepted</span>;
        case 3:
            return <span className="badge badge-primary">Packing</span>;
        case 4:
            return <span className="badge badge-warning">Shipping</span>;
        case 5:
            return <span className="badge badge-success">Delivered</span>;
        default:
            return <></>;
    }
}

export default OrderStatusBadge;
