import  axios, { AxiosResponse } from 'axios';
import * as AppConsts from '../models/app-url-consts';

const api_url = AppConsts.base_api_url + "/api/cart";

export const AddItemToCart = async (uid: number, pid: number, qty : number) => 
    await axios.post(api_url + "/AddToCart",null, {params : { userid : uid, prodid: pid, qty : qty }})

export const DeleteItem = async (uid: number, pid:number ) => 
    await axios.post(api_url + "/DeleteItem", null, {params: {userid : uid, prodid: pid}})

export const GetCart = async (uid:number) => await axios.get(api_url + "/GetCart", {params : { userid : uid}})

export const GetCartItem = async (uid:number, pid:number) => await axios.get(api_url + "/GetCartItem", {params : { userid : uid, pid: pid}})

export const GetCartItemsCount = async (uid: number)=> await axios.get(api_url + "/GetCartItemsCount", {params : { userid : uid}})

export const GetCartValue = async (uid: number)=> await axios.get(api_url + "/GetCartGrandTotalAmount", {params : { userid : uid}})

