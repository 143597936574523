import React, { useEffect, useState } from 'react';
import { ML_ProductCategory } from '../../models/api-dto';
import Switch from 'react-switch';
import * as cdb from '../../data-services/product-details-data';
import { Modal, ModalBody } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/esm/ModalHeader';

//props.id              => category Id
//props.showModal       => toggle modal window
//props.onSaved(copy)   => throws category object
//props.onClose()       => closes window

type attributes = {
    id :number,
    showModal :boolean,

    onSaved(arg: ML_ProductCategory) : void,
    onClose() : void
}

const ProductCategoryEdit = (props: attributes) => {
    const [selCat, setSelCat] = useState<ML_ProductCategory>(new ML_ProductCategory());

    useEffect(() => {
        if(props.showModal && +props.id > 0){
            cdb.GetCategoryById(props.id).then(res=>{
                setSelCat(res.data);
            });
        } else {
            setSelCat(new ML_ProductCategory());
        }
    }, [props.showModal])

    const handleOnChange = (val: boolean) => {
        setSelCat((prev: ML_ProductCategory) => { return { ...prev, IsActive: val } });
    }

    const onTextChange = (e: any) => {
        let val = e.target.value;
        setSelCat((prev: ML_ProductCategory) => { return { ...prev, CategoryName: val } });
    }

    const save = () => {
        cdb.SaveCategory(selCat).then(res => {
            let copy: ML_ProductCategory = { ...selCat, PRCTID: +res.data };
            props.onSaved(copy);
        });
    }

    const close = () => {
        //setShowModal(false);
        props.onClose();
    }


    return (
        <>
            <Modal show={props.showModal} size='sm' onHide={close} backdropClassName={'static'}>
                <ModalHeader>
                    <div className="h5 mb-0">Product Category</div>
                </ModalHeader>

                <ModalBody>
                    <div className="row">
                        <div className="col-12">
                            <label>Category name</label>
                            <input type="text" className="form-control" tabIndex={0}
                                value={selCat.CategoryName}
                                onChange={onTextChange} />
                        </div>
                        {selCat &&
                            <>
                                <div className="col-12 mt-3">
                                    <div className="d-flex flex-row align-items-center">
                                        <div>
                                            <Switch
                                                tabIndex={1}
                                                height={20}
                                                width={48}
                                                onColor="#3366ff"
                                                id={"IsActive"}
                                                uncheckedIcon={false}
                                                checked={selCat.IsActive}
                                                onChange={(val, event, id) => handleOnChange(val)}
                                            />
                                        </div>
                                        <div className="pl-2">Is Active</div>
                                    </div>
                                </div>
                            </>
                        }
                        <div className="col-12 text-center mt-3">
                            <button className="btn btn-primary mr-3" tabIndex={2} onClick={save}>Save</button>
                            <button className="btn btn-outline-primary" tabIndex={3} onClick={close}>Cancel</button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </>);
}

export default ProductCategoryEdit;