import React, { useEffect, useState } from 'react';
import { QS_ProductImage } from '../../models/api-dto';
import { ImageDTO } from '../../models/app-dto';
import * as cdb from '../../data-services/product-details-data';
import * as pdb from '../../data-services/products-data';
import ProductImageUploader from './product-image-uploader';
import * as factory from '../../models/object-factory';
import * as urls from '../../models/app-url-consts';
import * as AppConsts from '../../models/app-consts';
import _ from 'lodash';

//props.product_details_id -> product details Id

type attributes = {
    product_id : number,
    saveImagesTrigger : boolean;
    //onImageUpdated(qs_images : ImageDTO[]) : void
}

const ProductImagesEdit = (props: attributes) => {

    //const [productImages, setProductImages] = useState<QS_ProductImage[]>(new Array<QS_ProductImage>());
    const [imageDtos, setImageDtos] = useState<Array<ImageDTO>>(new Array<ImageDTO>());

    useEffect(() => {
        imageDtos.splice(0); //empty collection
        //if (props.product_id > 0) {
            loadAll();
        //}
    }, [props.product_id]);

    useEffect(()=>{
        SaveImages(props.product_id);
    },[props.saveImagesTrigger]);

    const loadAll = () => {
        pdb.GetProductImages(props.product_id).then(res => {

            //console.log(res.data);
            let copyImgDtos = [...imageDtos];
            
            if (res.data.length < 4) {
                let remaining = 4 - res.data.length;
                //console.log("remaining: " + remaining);
                
                for (let i = 0; i < remaining; i++) {
                    let imgdto = new ImageDTO();
                    imgdto.ProductImage = new QS_ProductImage();
                    imgdto.ImageFile=null;
                    imgdto.ImageBase64 = '';
                    copyImgDtos.push(imgdto);
                    //setImageDtos(copyImgDtos);    
                    //setImageDtos((prev: ImageDTO[]) => { return [...prev, imgdto] });
                }
                //setImageDtos(copyImgDtos);
            }


            if (res.data.length > 0) {
                for (let i = 0; i < res.data.length; i++) {
                    if (res.data[i].FLID > 0) {
                            //console.log('setting db image');
                            let imgdto = new ImageDTO();
                            imgdto.ImageFile=null;
                            imgdto.ProductImage = res.data[i];
                            imgdto.ImageBase64 =  urls.product_images_url+res.data[i].FileName;
                            copyImgDtos.push(imgdto);
                    } 
                }
            }

            setImageDtos(copyImgDtos);
        });
    }

    const updateImage =(idx:number, image:File) =>{
        console.log("image edit : " + idx + " : " + image.name);
        
        let imgInfo = imageDtos[idx];
        imgInfo.ImageFile = image;
        
        imageDtos.splice(idx,1,imgInfo);
        setImageDtos(imageDtos);
        
    }

    const deleteImage  = (idx:number) => {
        let imgInfo = _.clone(imageDtos);
        imgInfo[idx].ProductImage.FileName = AppConsts.IMAGE_REMOVED;
        setImageDtos(imgInfo);
        console.log("Deleteing image id: " + imgInfo[idx].ProductImage.FLID);
        
    }

    const SaveImages = (prdid: number) => {
        let imagesCopy = _.clone(imageDtos);
        for(let i = 0; i < imageDtos.length; i++){
          if (imagesCopy[i].ProductImage.FileName === AppConsts.IMAGE_REMOVED) {
            //console.log('deleting image file: '  + imagesCopy[i].ProductImage.FLID + ", " + imagesCopy[i].ProductImage.FileName);
            cdb.RemoveProductImage(imagesCopy[i].ProductImage.FLID);

            let dtoCopy = _.clone(imageDtos);
            dtoCopy[i].ProductImage.FLID = 0;
            dtoCopy[i].ProductImage.FileName="";
            dtoCopy[i].ImageFile =null;
            setImageDtos(dtoCopy);
            
          } else if (imagesCopy[i].ImageFile) {
            cdb.UploadImageFile(imagesCopy[i].ImageFile, imagesCopy[i].ProductImage.FLID, prdid).then(res => {
              let dtoCopy = _.clone(imageDtos);
              dtoCopy[i].ImageFile=null;
              dtoCopy[i].ProductImage = res.data; //get the updated copy with saved object ID
              console.log(JSON.stringify(res.data,null,2));
              setImageDtos(dtoCopy);
            });
          } 
        }
      }
    

    return (<>
        
            {imageDtos && imageDtos.length === 0 &&
                <ProductImageUploader 
                key={"img-1"}
                size={160} 
                image={factory.createProductImage()} 
                onChange={(e:File) => { updateImage(0,e) }} 
                onImageRemoved={()=>{}}
                />
            }

            {imageDtos && imageDtos.length > 0 &&
                imageDtos.map((img:any, idx:number) => {
                    return <div key={'div'+idx} className="col-md-3 text-center d-table">
                        <ProductImageUploader 
                        size={160} 
                        image={img} 
                        onChange={(e:File)=> updateImage(idx,e)} 
                        onImageRemoved={()=>{deleteImage(idx)}}/>
                        </div>
                })
            }
        
    </>);
}

export default ProductImagesEdit;