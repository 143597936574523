import {ML_ProductPrice, QS_ProductDetail, QS_ProductImage } from "./api-dto"

export class ImageDTO {
    
 public ProductImage : QS_ProductImage = new QS_ProductImage();   
 public ImageFile : any;
 public ImageBase64 :string ='';

}

