import { useEffect, useState } from 'react';
import UserAddresses from "../../users/user-addresses"
import * as cdb from '../../data-services/cart-data';
import * as utils from '../../models/app-utils';
import * as sdb from '../../data-services/sales-data';
import * as consts from '../../models/app-consts';
import {toast} from 'react-toastify';
import * as actions from '../../redux-infra/rdx-actions';
import { useDispatch } from 'react-redux';

const OrderConfirm = (props: any) => {

    const[total, setTotal ] = useState(0);

    const dispatch = useDispatch();

    useEffect(()=>{
        cdb.GetCartValue(utils.GetUserObj().AID).then(res=>{
            setTotal(res.data);
        })
    },[])

    const confirmOrder = ()=> {
        sdb.ConfirmOrder(utils.GetUserObj().AID, 0, consts.PAYMODE_CASH).then(res=>{
            if(res.data > 0){
                dispatch(actions.actionCartStatus(res.data));
                toast.success('Thank you! Your order has been placed.');
            }
        })
    }

    return (<>

        <section className="section-content padding-y bg">
            <div className="container">

                <div className="row">
                    <div className="col-12">
                        <h3>Your Order Confirmation</h3>
                    </div>
                </div>
                <div className="row">
                    <main className="col-md-8">
                        <div>

                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <div className="card">
                                        <div className="card-header font-weight-bold">
                                            Delivery Address
                                        </div>
                                        <div className="card-body">
                                            <h6>Pavan Kumar G R</h6>
                                    115, 8th Block, 4th Cross<br />
                                    Near M.K.S School <br />
                                    Bangalore <br />
                                        </div>
                                        <div className="card-footer text-right">
                                            <button className="btn btn-outline-primary">Edit</button>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-12 col-md-6">
                                    <div className="card">
                                        <div className="card-header font-weight-bold">
                                            Payment Mode
                                        </div>
                                        <div className="card-body">
                                        <label className="js-check box">
                                                <input type="radio" name="dostavka" defaultValue="option1" defaultChecked />
                                                <h6 className="title">Cash on Delivery</h6>
                                                <p className="text-muted">We will deliver the items and collect the cash</p>
                                            </label>
                                            </div>
                                    </div>
                                </div>

                            </div>



                            {/* <article className="card mb-4 mt-4">
                                <div className="card-body">
                                    <h4 className="card-title mb-4">Delivery info</h4>
                                    <div className="row">
                                        <div className="form-group col-sm-6">
                                            <label className="js-check box active">
                                                <input type="radio" name="dostavka" defaultValue="option1" defaultChecked />
                                                <h6 className="title">Delivery</h6>
                                                <p className="text-muted">We will deliver by DHL Kargo</p>
                                            </label>
                                        </div>
                                        <div className="form-group col-sm-6">
                                            <label className="js-check box">
                                                <input type="radio" name="dostavka" defaultValue="option1" />
                                                <h6 className="title">Pick-up</h6>
                                                <p className="text-muted">Come to our office to somewhere </p>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </article> */}
                        </div>

                    </main> {/* col.// */}
                    <aside className="col-md-4">
                        <div className="card shadow">
                            <div className="card-body">
                                <h4 className="mb-3">Overview</h4>
                                <dl className="dlist-align">
                                    <dt className="text-muted">Payment method:</dt>
                                    <dd>Cash</dd>
                                </dl>
                                <hr />
                                <dl className="dlist-align">
                                    <dt>Grand Total:</dt>
                                    <dd className="h5">Rs. {total}</dd>
                                </dl>
                                <hr />
                                <p className="small mb-3 text-muted">By clicking you are agreed to the terms of conditions </p>
                                <button onClick={confirmOrder} className="btn btn-success btn-block">Place Order</button>
                            </div> {/* card-body.// */}
                        </div>

                    </aside> {/* col.// */}
                </div> {/* row.// */}


            </div>
        </section>
    </>);
}

export default OrderConfirm;