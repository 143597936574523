import React, { useEffect, useState } from 'react';
import UpdownCounterControl from '../controls/updown-counter-control';
import * as pdb from '../data-services/product-details-data';
import { ProductDetailsView } from '../models/api-dto';
import * as urls from '../models/app-url-consts';
import ProductListItem from './products-list/product-list-item';
import ProductsListView from './products-list/products-list-view';
import ProductConfig from './product-config';
import { useDispatch } from 'react-redux';
import * as actions from '../redux-infra/rdx-actions';

const ProductsList = (props: any) => {

  const [productList, setProductList] = useState([]);
  const [productDetails, setProductDetails] = useState<ProductDetailsView>(new ProductDetailsView());
  const [showProductEditPage,setShowProductEditPage] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    loadAllProducts();
  }, []);

  const loadAllProducts=()=>
  {
    pdb.GetProductDetailsList().then(res => {
      setProductList(res.data);
    });
  }

  const ProductSaved = (isReload:boolean) => {
    if(isReload)
      loadAllProducts();
    
    setShowProductEditPage(false);
  }

  return (<>
      {!showProductEditPage &&
      <ProductsListView 
      products={productList} 
      productDeleted={()=>loadAllProducts()}
      editProduct={(pv:ProductDetailsView)=>{
        //setProductDetails(pv);
        //console.log("listing:" + JSON.stringify(pv,null,2));
        
        dispatch(actions.actionUpdateProductDetail(pv));
        setShowProductEditPage(true);
      }}/>
      }

      {showProductEditPage &&
      <ProductConfig detailedUpdated={ProductSaved}/>}
  </>);
}

export default ProductsList;