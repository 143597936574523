import React, { useRef, useState } from 'react';
import { Plus } from 'react-bootstrap-icons';
import { ImageDTO } from '../../models/app-dto';
import * as AppConsts from '../../models/app-consts';
import _ from 'lodash';

type attributes = {
  image : ImageDTO,
  size: number,

  onChange(image : File) : void,
  onImageRemoved() : void
}

const ProductImageUploader = (props: attributes) => {

  const [previewImage, setPreviewImage] = useState<ImageDTO>(props.image);
  const fileRef = useRef<any>();

  const handleChange = (event: any) => {
    if (event.target.files.length > 0) {
      let file: File = event.target.files[0];
      let copyDto = _.clone(previewImage);
      copyDto.ImageFile=file;
      copyDto.ImageBase64 = URL.createObjectURL(file);
      copyDto.ProductImage.FileName="";
      // let imgPrev = {
      //   ...previewImage,
      //   ImageFile: file,
      //   ImageBase64: URL.createObjectURL(file),
      //   ProductImage: { ...previewImage.ProductImage, FileName : ''} //re-sets if it was previously set as 'removed'.
      // };

      setPreviewImage(copyDto);
      props.onChange(copyDto.ImageFile);
    }
  }

  const fileClick = () => {
    if (fileRef)
      fileRef.current.click();
  }

  const removeImage = () =>{
    setPreviewImage({..._.clone(previewImage), ImageBase64:'',ImageFile:{}});
    props.onImageRemoved();
  }

  return (
    <>
      <figure className="img-wrap card-product-grid mb-0 card-sm d-table-cell align-middle border preview-img">
          {previewImage.ImageBase64 !== '' &&
          <span className="topbar" onClick={removeImage}>
            <a className="float-right text-danger mr-2"><i className="fa fa-times"></i></a>
          </span>
          }
          {!previewImage.ImageBase64 &&
            <Plus size={40} onClick={fileClick} className="cursor-pointer"/>
          }

          {previewImage.ImageBase64 !== '' &&
            <img onClick={fileClick} className="cursor-pointer" style={{ maxWidth: props.size, maxHeight: props.size, padding: 0 }} 
            src={previewImage.ImageBase64} />
          }
          <input type="file" ref={fileRef} onChange={handleChange} style={{ opacity: 0, width: 0, height: 0 }} />
      </figure>
    </>
  );

}

export default ProductImageUploader;