import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as sdb from '../../data-services/sales-data';
import { ProductDetailsView, SaleOrderLineItemView, SaleOrderView } from "../../models/api-dto";
import { product_images_url } from '../../models/app-url-consts';
import { mapStateToProps } from "../../redux-infra/rdx-state-mapper";
import OrderItemsView from "./order-items-view";
import OrderStatusTrackingButtons from "./order-status-buttons";
import OrderTrackingMap from "./order-tracking-map";
import * as rdxConsts from '../../redux-infra/rdx-constants';

type attributes = {
  close(): void,
  items: SaleOrderLineItemView[]
  saleOrder: SaleOrderView,
}
const OrdersAdminView = (props: attributes) => {
  return (<>
  {props.saleOrder &&
    <div className="row">
      <main className="col-md-12">
        <article className="card">
          <header className="card-header">
            <div className="d-flex flex-column flex-md-row">
              <div className="flex-md-grow-1 py-2">
                  <OrderStatusTrackingButtons status={props.saleOrder.SaleOrder.OrderStatus} orderId={props.saleOrder.SaleOrder.SOID}/>
              </div>
              <div className="py-2">
                <button onClick={props.close} className="btn btn-outline-primary float-right">&lt; Back</button>
              </div>
            </div>
          </header>
          <OrderItemsView items={props.items} saleOrder={props.saleOrder} />
        </article>
      </main>
    </div>
  }
  </>)
}

export default OrdersAdminView;