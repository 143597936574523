import React from 'react'
import { Link } from 'react-router-dom';
import { product_images_url } from '../../models/app-url-consts';

type attributes = {
    product : any
}

const ProductOneView = (props:attributes) =>  {

    return (<>
    
    <div className="col-md-3 cursor-pointer" key={"pov_"+ props.product.ProductID}>
          <div className="card card-product-grid">
            <Link to={"/product/" + props.product.ProductID} className="img-wrap"> 
              <img src={product_images_url + props.product.Image}  /> 
            </Link>
            <figcaption className="info-wrap">
              <a className="title ellipse-text">{props.product.ProductName}</a>
              {/* <div className="rating-wrap">
                <ul className="rating-stars">
                  <li style={{width: '80%'}} className="stars-active"> 
                    <i className="fa fa-star" /><i className="fa fa-star" /><i className="fa fa-star" /><i className="fa fa-star" /><i className="fa fa-star" />
                  </li>
                  <li>
                    <i className="fa fa-star" /><i className="fa fa-star" /><i className="fa fa-star" /><i className="fa fa-star" /><i className="fa fa-star" /> 
                  </li>
                </ul>
                <span className="label-rating text-muted"> 34 reviws</span>
              </div> */}
              <div className="price mt-1">Rs. {props.product.Price}</div> {/* price-wrap.// */}
            </figcaption>
          </div>
        </div> 
    
    </>);
}

export default ProductOneView;