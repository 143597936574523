import  axios, { AxiosResponse } from 'axios';
import { ML_ProductCategory, ML_ProductPrice, QS_ProductDetail, QS_ProductImage } from '../models/api-dto';
import * as AppConsts from '../models/app-url-consts';

const api_url = AppConsts.base_api_url + "/api/ProductDetails";

export const SaveCategory = async (c : ML_ProductCategory) => await axios.post(api_url + "/save", c);

export const GetAllCategory = async () => await axios.get(api_url + "/GetAll");

export const GetProductDetailsList = async () =>  await axios.get(api_url + "/GetProductDetailsList");
export const GetProductDetailsListByCategoryId = async (catId:number) =>  
await axios.get(api_url + "/GetProductDetailsListByCategory", {params:{CatId : catId}});

export const GetProductDetails = async (id:number) =>  
await axios.get(api_url + "/GetProductDetails",{params: { prdid : id}});


export const GetCategoryWithStats = async () => await axios.get(api_url + "/GetCategoryStats");

export const GetCategoryById = async (id:number) => await axios.get(api_url + "/GetProductCategoryByID",{params : {id}});


export const UploadImageFile = async (imgFile:File, fileId :number, prdid : number) =>{
    const formData = new FormData();
    formData.append("imgFile",imgFile);
    // const postConfig = {
    //     headers: {  
    //       'content-type': 'multipart/form-data',
    //     },
    //   };
    return await axios.post(api_url + "/UploadProductImage",formData,{params: { flid: fileId, prdid}});  
} 

export const SaveProductPrice = async (price : ML_ProductPrice) => 
    await axios.post(api_url + "/SaveProductPrice",price)

export const SaveProductDetails = async (details : QS_ProductDetail) => 
    await axios.post(api_url + "/SaveProductDetails",details)

export const RemoveProductImage = async (id:number) => await axios.post(api_url + "/RemoveImage",null,{params:{flid:id}});

export const DeleteProductDetails = async (pid:number) => await axios.post(api_url + "/DeleteProductDetails",null,{params: {pid : pid}})

