import  axios, { AxiosResponse } from 'axios';
import { ML_ProductPrice, ML_UnitMeasure, ProductView, QS_ProductDetail, QS_ProductImage } from '../models/api-dto';
import * as AppConsts from '../models/app-url-consts';

const api_url = AppConsts.base_api_url + "/api/products";

export const UploadImageFile = async (imgFile:File) =>{
    const formData = new FormData();
    formData.append("imgFile",imgFile);
    // const postConfig = {
    //     headers: {  
    //       'content-type': 'multipart/form-data',
    //     },
    //   };
    return await axios.post(api_url + "/UploadProductImage",formData);  
} 

export const GetImageFileAsString = async (flid :number) => 
await axios.get(api_url + "/GetProductImage64ByFileID", {params:{flid}});

export const GetProductImages = async (prdid:number) : Promise<AxiosResponse<QS_ProductImage[]>> => 
await axios.get(api_url + "/GetProductImages",{params: {prdid}})

export const GetAllUnConfiguredProducts = async (): Promise<AxiosResponse<ProductView[]>> => {
    return await axios.get(api_url + '/GetUnConfiguredProducts');
}


//----------- FROM Tracker+ ----------------------
export const GetAllProducts = async (): Promise<AxiosResponse<ProductView[]>> => {
    return await axios.get(api_url + '/allProducts');
}

export const GetAllUnits = async (): Promise<AxiosResponse<ML_UnitMeasure[]>> => {
    return await axios.get(api_url + '/allUnits');
}

export const GetProduct = async (id: number): Promise<AxiosResponse<ProductView>> => {
    return await axios.get(api_url + '/getProduct?prid=' + id);
}

export const SuggestUnits = async (id: number): Promise<AxiosResponse<string[]>> => {
    return await axios.get(api_url + '/suggestUnits');
}

export const SuggestProducts = async (id: number): Promise<AxiosResponse<string>> => {
    return await axios.get(api_url + '/suggestProducts');
}

export const SaveProduct = async (pv: ProductView) => {
    return await axios.post(api_url + "/save", pv);
}

export const SaveUnit = async (un: ML_UnitMeasure) => {
    return await axios.post(api_url + "/SaveUnit", un);
}
