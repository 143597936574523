import  axios, { AxiosResponse } from 'axios';
import * as AppConsts from '../models/app-url-consts';

const api_url = AppConsts.base_api_url + "/api/SaleOrders";

export const ConfirmOrder = async (userId: number, addressId: number, paymode: string) => 
await axios.post(api_url + "/ConfirmOrder",null, {params:{ userID: userId, addressId: addressId, payMode: paymode}});

export const UpdateOrderStatus = async(odid: number, stat:number) => 
    await axios.post(api_url + "/UpdateOrderStatus", null, {params :{ soid: odid, status : stat}})

export const CancelSaleOrder = async(odid: number) => 
    await axios.post(api_url + "/UpdateOrderStatus", null, {params :{ soid: odid, status : 0}})    
    //await axios.post(api_url + "/CancelOrder", null, {params :{ soid: odid}})

export const GetSaleOrderItems = async (odid: number) => await axios.get(api_url + "/GetSaleOrderItems", {params: {soid: odid}})

export const GetSaleOrderView = async (odid: number) => await axios.get(api_url + "/GetSaleOrderView", {params: {soid: odid}})

export const GetAllPendingSaleOrders = async () => await axios.get(api_url + "/GetAllPendingSaleOrders")

export const GetOrdersByStatus = async (stat: number) => await axios.get(api_url + "/GetOrdersByStatus", {params:{stat}})



