import React, { useEffect, useState } from 'react';

type attributes = {
    qty :number
    onQtyUpdated(qty :number) : void
}

const ItemQtyChangeButton =(props:attributes) =>{

    const[qty, setQty] = useState(props.qty);

    useEffect(()=>{
      if(props.qty <= 0) { 
        setQty(1);
        props.onQtyUpdated(1);
      } else 
      setQty(props.qty);
    },[props.qty])

    const addItemToCart = (newQty : number) => {
        let total = qty+newQty;
        if(total <= 0)
          total = 0
        setQty(total);
        props.onQtyUpdated(total);
    }

    const add = () => addItemToCart(1);
    const remove = ()=> addItemToCart(-1);

    return(<>
    <div className="input-group input-spinner">
            <div className="input-group-prepend">
              <button onClick={remove} className="btn btn-light" type="button" id="button-plus"> <i className="fa fa-minus" /> </button>
            </div>
            <input type="text" readOnly={true} className="form-control bg-white" value={qty} />
            <div className="input-group-append">
              <button onClick={add} className="btn btn-light" type="button" id="button-minus"> <i className="fa fa-plus" /> </button>
            </div>
          </div> {/* input-group.// */}
    </>);
}

export default ItemQtyChangeButton;