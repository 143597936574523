import React from 'react';

const WebsiteFeaturesSection = (props:any)=> {

    return(
        
      <section className="section-content padding-y-sm">
      <div className="container">
        <article className="card card-body">
          <div className="row">
            <div className="col-md-4">	
              <figure className="item-feature">
                <span className="text-primary"><i className="fa fa-2x fa-truck" /></span>
                <figcaption className="pt-3">
                  <h5 className="title">Fast delivery</h5>
                  <p>Dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                    tempor incididunt ut labore </p>
                </figcaption>
              </figure> {/* iconbox // */}
            </div>{/* col // */}
            <div className="col-md-4">
              <figure className="item-feature">
                <span className="text-primary"><i className="fa fa-2x fa-landmark" /></span>	
                <figcaption className="pt-3">
                  <h5 className="title">Creative Strategy</h5>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                  </p>
                </figcaption>
              </figure> {/* iconbox // */}
            </div>{/* col // */}
            <div className="col-md-4">
              <figure className="item-feature">
                <span className="text-primary"><i className="fa fa-2x fa-lock" /></span>
                <figcaption className="pt-3">
                  <h5 className="title">High secured </h5>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                  </p>
                </figcaption>
              </figure> {/* iconbox // */}
            </div> {/* col // */}
          </div>
        </article>
      </div> {/* container .//  */}
    </section>
    
    );
}

export default WebsiteFeaturesSection;