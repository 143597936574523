import React, { useEffect, useState } from 'react';
import * as cdb from '../data-services/product-details-data';
import * as AppAct from '../redux-infra/rdx-actions';

import { ML_ProductCategory } from '../models/api-dto';
import { useDispatch } from 'react-redux';

// type attributes = {
//   onCategoryClicked(cat : ML_ProductCategory): void,
// }

const AppMainNav = (props: any) => {

  const [cats, setCats] = useState<ML_ProductCategory[]>();
  const appDisp = useDispatch();

  // const loadProductsAll = () => {
  //   document.body.click();
  //   cdb.GetProductDetailsList().then(res => {
  //     appDisp(AppAct.actionProductsList(res.data));
  //     appDisp(AppAct.actionProductsListTitle('All Products'));
  //   })
  // }

  const loadProductsByCategory = (cat: ML_ProductCategory) => {
    document.body.click();
    appDisp(AppAct.actionProductsListTitle(cat.CategoryName));
    appDisp(AppAct.actionProductsCategoryId(cat.PRCTID));
    // if (cat.PRCTID === 0)
    //   loadProductsAll();
    // else
    //   cdb.GetProductDetailsListByCategoryId(cat.PRCTID).then(res => {
    //     appDisp(AppAct.actionProductsList(res.data));
    //     appDisp(AppAct.actionProductsListTitle(cat.CategoryName));
    //   });
  }

  useEffect(() => {
    cdb.GetAllCategory().then(res => {
      setCats(res.data);
    });

    //loadProductsAll();
  }, [])

  const HorizontalMenusItems = () => {
    return (
      <>
        {cats &&
          cats?.filter(x => x.IsActive).map((c: ML_ProductCategory, idx: number) => {
            return <li className="nav-item" key={"cat_m_" + idx}>
              <a onClick={() => loadProductsByCategory(c)} key={"cat_link" + idx} className="nav-link cursor-pointer">
                {c.CategoryName}</a>
            </li>
          })}
      </>);
  }

  const DropdownCategoryItems = () => {
    return (<>
      {cats &&
        cats?.filter(x => x.IsActive).map((c: ML_ProductCategory, idx: number) =>
          <a onClick={() => loadProductsByCategory(c)} key={"ddc" + idx} className="dropdown-item cursor-pointer">
            {c.CategoryName}
          </a>
        )}
    </>);
  }


  return (
    <nav className="navbar navbar-main navbar-expand-lg navbar-light border-bottom">
      <div className="container">
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#main_nav" aria-controls="main_nav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="main_nav">
          <ul className="navbar-nav">
            <li className="nav-item dropdown" key="id_0">
              <a className="nav-link pl-0" data-toggle="dropdown" href="#">
                <strong> <i className="fa fa-bars" /> &nbsp;  All Products</strong></a>
              <div className="dropdown-menu">
                <a className="dropdown-item cursor-pointer" onClick={() => loadProductsByCategory(new ML_ProductCategory())} >All</a>
                <div className="dropdown-divider" />
                {DropdownCategoryItems()}
              </div>
            </li>
            {HorizontalMenusItems()}
          </ul>
        </div> 
      </div> 
    </nav>
  );
}

export default AppMainNav;