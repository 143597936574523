import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as utils from '../models/app-utils';
import { mapStateToProps } from "../redux-infra/rdx-state-mapper";
import * as rxConsts from '../redux-infra/rdx-constants';
import { useEffect, useState } from "react";

const AdminTopBar = (props: any) => {

  const [isUserSignedIn, setIsUserSignedIn] = useState(false);

  useEffect(()=>{
    setIsUserSignedIn(utils.IsUserLoggedIn());
  },[props[rxConsts.APP_USER_STATUS]])


  return (<>

    {!isUserSignedIn &&
      <div style={{height:'5px', backgroundColor:'rgb(22, 80, 224)', width:'100%'}}></div>
    }

    {isUserSignedIn &&
      <nav className="navbar navbar-dark navbar-expand p-0 bg-primary">
        <div className="container">
          <ul className="navbar-nav d-none d-md-flex mr-auto">
            {/* <li className="nav-item"><Link to={'/store'} className="nav-link">Home</Link></li> */}
            <li className="nav-item"><Link to={'/backstore'} className="nav-link">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-gear" viewBox="0 0 16 16">
                <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z" />
                <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z" />
              </svg>&nbsp;Settings</Link>
            </li>
            {/* <li className="nav-item"><a className="nav-link" href="#">Delivery</a></li>
              <li className="nav-item"><a className="nav-link" href="#">Payment</a></li> */}
          </ul>
          <ul className="navbar-nav">
            <li className="nav-item"><a className="nav-link text-light"> Help: +797979-8090 </a></li>
            {/* <li className="nav-item dropdown cursor-pointer">
                <a className="nav-link dropdown-toggle" data-toggle="dropdown"> English </a>
                <ul className="dropdown-menu dropdown-menu-right" style={{ maxWidth: '100px' }}>
                  <li><a className="dropdown-item" href="#">Arabic</a></li>
                  <li><a className="dropdown-item" href="#">Russian </a></li>
                </ul>
              </li> */}
          </ul> {/* list-inline //  */}
        </div>
      </nav>
    }
  </>);
}

export default connect (mapStateToProps) (AdminTopBar);