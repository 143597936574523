import React, { useEffect, useState } from 'react';
import WebsiteFeaturesSection from '../../layout/website-features-section';
import ProductOneBigImageView from './product-one-big-image-view';
import * as pdb from '../../data-services/product-details-data';
import * as cdb from '../../data-services/cart-data';
import { ProductDetailsView } from '../../models/api-dto';
import * as AppAct from '../../redux-infra/rdx-actions';
import { useDispatch } from 'react-redux';
import * as glob from '../../models/app-consts';
import ItemQtyChangeButton from '../cart/item-qty-change-button';
import AddToCartButton from '../cart/add-to-cart-button';
import * as utils from '../../models/app-utils';

const ProductPurchaseView = (props: any) => {

    const [productId] = useState(+props.match.params.id);
    const [product, setProduct] = useState<ProductDetailsView>();
    const [currentQty, setCurrentQty] = useState(0);

    const appDisp = useDispatch();

    useEffect(()=>{
        pdb.GetProductDetails(productId).then(res=>{
            setProduct(res.data);
        })

        cdb.GetCartItem(utils.GetUserObj().AID, productId).then(res=>{
            if(res.data){
                setCurrentQty(res.data.CartItem.Qty);
            }
        })

    },[])

    // const addItemToCart = () => {
    //     cdb.AddItemToCart(glob.CurrentUserID,productId,currentQty).then(res=>{
    //         appDisp(AppAct.actionCartStatus(currentQty));
    //     });
    // }

    // const AddToCartButton = ()=> {
    //     return <button className="btn btn-outline-primary" onClick={()=>{addItemToCart()}}> 
    //         <span className="text">Add to cart</span> <i className="fas fa-shopping-cart" />
    //     </button>
    // }


    return (
        <>
            <section className="section-content padding-y bg">
                <div className="container">
                    {/* ============================ COMPONENT 1 ================================= */}
                    <div className="card">
                        <div className="row no-gutters">
                            <aside className="col-md-6">
                                <ProductOneBigImageView product_id={productId} />
                            </aside>
                            <main className="col-md-6 border-left">
                                <article className="content-body">
                                    <h2 className="title">{product?.Product.ProductName}</h2>
                                    <div className="rating-wrap my-3">
                                        <ul className="rating-stars">
                                            <li style={{ width: '80%' }} className="stars-active">
                                                <img src="/assets/theme/fresh-blue/images/icons/stars-active.svg" alt="" />
                                            </li>
                                            <li>
                                                <img src="/assets/theme/fresh-blue/images/icons/starts-disable.svg" alt="" />
                                            </li>
                                        </ul>
                                        <small className="label-rating text-muted">132 reviews</small>
                                        <small className="label-rating text-success"> <i className="fa fa-clipboard-check" /> 154 orders </small>
                                    </div> {/* rating-wrap.// */}
                                    <div className="mb-3">
                                        <var className="price h4">Rs. {product?.Price.SalePricePerUnit}</var>
                                        <span className="text-muted">/per item</span>
                                    </div>
                                    <p>{product?.Details.ProductDescription}</p>
                                    {/* <dl className="row">
                                        <dt className="col-sm-3">Model#</dt>
                                        <dd className="col-sm-9">Odsy-1000</dd>
                                        <dt className="col-sm-3">Color</dt>
                                        <dd className="col-sm-9">Brown</dd>
                                        <dt className="col-sm-3">Delivery</dt>
                                        <dd className="col-sm-9">Russia, USA, and Europe </dd>
                                    </dl> */}
                                    <hr />
                                    <div className="row">
                                        <div className="form-group col-md flex-grow-0">
                                            <label>Quantity</label>

                                            <ItemQtyChangeButton onQtyUpdated={(qty:number)=>setCurrentQty(qty)} qty={currentQty} />

                                            {/* <div className="input-group mb-3 input-spinner">
                                                <div className="input-group-prepend">
                                                    <button className="btn btn-light" type="button" id="button-plus"> + </button>
                                                </div>
                                                <input type="text" className="form-control" defaultValue={1} />
                                                <div className="input-group-append">
                                                    <button className="btn btn-light" type="button" id="button-minus"> − </button>
                                                </div>
                                            </div> */}
                                        </div> {/* col.// */}
                                        {/* <div className="form-group col-md">
                                            <label>Select size</label>
                                            <div className="mt-2">
                                                <label className="custom-control custom-radio custom-control-inline">
                                                    <input type="radio" name="select_size" defaultChecked className="custom-control-input" />
                                                    <div className="custom-control-label">Small</div>
                                                </label>
                                                <label className="custom-control custom-radio custom-control-inline">
                                                    <input type="radio" name="select_size" className="custom-control-input" />
                                                    <div className="custom-control-label">Medium</div>
                                                </label>
                                                <label className="custom-control custom-radio custom-control-inline">
                                                    <input type="radio" name="select_size" className="custom-control-input" />
                                                    <div className="custom-control-label">Large</div>
                                                </label>
                                            </div>
                                        </div>  */}
                                    </div> {/* row.// */}
                                    <a href="#" className="btn  btn-primary mr-3"> Buy now </a>
                                    <AddToCartButton productId={productId} currentQty={currentQty}/>
                                </article> {/* product-info-aside .// */}
                            </main> {/* col.// */}
                        </div> {/* row.// */}
                    </div> {/* card.// */}
                    {/* ============================ COMPONENT 1 END .// ================================= */}


                    {/* ============================ COMPONENT 4  ================================= */}
                    {/* <article className="card mt-5">
                        <div className="card-body">
                            <div className="row">
                                <aside className="col-md-6">
                                    <h5>Parameters</h5>
                                    <dl className="row">
                                        <dt className="col-sm-3">Display</dt>
                                        <dd className="col-sm-9">13.3-inch LED-backlit display with IPS</dd>
                                        <dt className="col-sm-3">Processor</dt>
                                        <dd className="col-sm-9">2.3GHz dual-core Intel Core i5</dd>
                                        <dt className="col-sm-3">Camera</dt>
                                        <dd className="col-sm-9">720p FaceTime HD camera</dd>
                                        <dt className="col-sm-3">Memory</dt>
                                        <dd className="col-sm-9">8 GB RAM or 16 GB RAM</dd>
                                        <dt className="col-sm-3">Graphics</dt>
                                        <dd className="col-sm-9">Intel Iris Plus Graphics 640</dd>
                                    </dl>
                                </aside>
                                <aside className="col-md-6">
                                    <h5>Features</h5>
                                    <ul className="list-check">
                                        <li>Best performance of battery</li>
                                        <li>5 years warranty for this product</li>
                                        <li>Amazing features and high quality</li>
                                        <li>Best performance of battery</li>
                                        <li>5 years warranty for this product</li>
                                    </ul>
                                </aside>
                            </div> 
                        </div> 
                    </article>  */}
                    {/* ============================ COMPONENT 4  .//END ================================= */}

                </div>

                <WebsiteFeaturesSection></WebsiteFeaturesSection>

            </section>




        </>
    );
}

export default ProductPurchaseView;