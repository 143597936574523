import React from 'react';
import UserAddresses from './user-addresses';
import UserInfo from './user-info';
import UserPasswordChange from './user-password-change';

const UserProfile = (props: any) => {
  return (<>

    <UserInfo/>
    <br/>
    <UserPasswordChange/>
    <br/>
    <UserAddresses/>

   </>)
}

export default UserProfile;