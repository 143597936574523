import { SaleOrderView } from "../../models/api-dto";
import * as sdb from '../../data-services/sales-data';
import * as actions from '../../redux-infra/rdx-actions';
import { useDispatch } from "react-redux";

type attributes = {
    saleOrder: SaleOrderView,
  }

const OrderViewHeader = (props:attributes)=> {

    const dispatch = useDispatch();

    const cancelOrder = ()=> {
        console.log('cancel initi ' + props.saleOrder.SaleOrder.SOID);
        
        dispatch(actions.actionCancelOrder(props.saleOrder.SaleOrder.SOID));
    }

    return (<>
    
    <header className="card-header py-3" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <strong className="d-inline-block mr-3">Order ID: {props.saleOrder.SaleOrder.OrderNumber}</strong>
            <span>Order Date: {props.saleOrder.SaleOrder.OrderDate}</span>
            {props.saleOrder.SaleOrder.OrderStatus < 4 && props.saleOrder.SaleOrder.OrderStatus !== 0 &&
                <button onClick={(e:any)=>cancelOrder()} className="btn btn-outline-danger float-right">Cancel Order</button>
            }
        </header>

    </>)

}
export default OrderViewHeader;