import React, { useEffect, useState } from 'react';
import ControlEx from '../../controls/controlEx';
import MyInput from '../../controls/my-input';
import MyTextArea from '../../controls/my-textarea';
import ProductCategoryDropdown from '../product-category/product-category-dropdown';
import ProductDropDown from './product-dropdown';
import * as yup from 'yup';
import { ML_ProductCategory, ProductDetailsView, QS_ProductImage, QS_ProductParameter } from '../../models/api-dto';
import { ImageDTO } from '../../models/app-dto';
import ProductImagesEdit from './product-images-edit';
import * as actions from '../../redux-infra/rdx-actions';
import * as rdxConsts from '../../redux-infra/rdx-constants';
import { useDispatch } from 'react-redux';
import * as pdb from '../../data-services/product-details-data';


type attributes = {
  obj: ProductDetailsView,
  saveImagesToggle: boolean;

  errorObject: any

  dataUpdated(arg: any): void
}

const ProductDetailsEdit = (props: attributes) => {
  const [obj, setObj] = useState<ProductDetailsView>(props.obj);
  // const [attr, setAttr] = useState<QS_ProductParameter>(new QS_ProductParameter());
  //const [verr, setVErr] = useState<any>();
  useEffect(() => {
    setObj(props.obj);
  }, [props.obj])

  const dispatch = useDispatch();

  const handlePriceInput = (e: any) => {
    let name = e.target.name;
    let val = e.target.value;
    let updatedCopy: ProductDetailsView = { Category: new ML_ProductCategory(), Image : new QS_ProductImage(), Product: obj.Product, Price: { ...obj.Price, [name]: val }, Details: { ...obj.Details } };
    setObj({ ...updatedCopy });
    props.dataUpdated({ ...updatedCopy });
  }

  const handleIdSelection = (name: string, pid: number) => {
    handleDetailsInput({ target: { name, value:pid } });

    if (name === 'ProductID') {
      pdb.GetProductDetails(pid).then(res => {
        if (res.data) {
          dispatch(actions.actionUpdateProductDetail(res.data));
        }
      })
    }

  }

  const handleDetailsInput = (e: any) => {
    let name = e.target.name;
    let val = e.target.value;
    let updatedCopy: ProductDetailsView = { Category: new ML_ProductCategory(), Image : new QS_ProductImage(), Product: obj.Product, Price: { ...obj.Price }, Details: { ...obj.Details, [name]: val } };
    setObj({ ...updatedCopy });
    props.dataUpdated({ ...updatedCopy });
  }

  return (
    <>
      <div className="form-row mt-3">
        <div className="col form-group">
          <label>Product Name</label>
          <ProductDropDown disabled={obj.Details.PDID > 0} product_id={obj.Details.ProductID} onSelect={(id: number) => { handleIdSelection('ProductID', id) }} />
        </div>
        {/* <pre>{JSON.stringify(details, null, 2)}</pre> */}
      </div>
      <div className="form-group">
        <label>Product Category</label>
        <ProductCategoryDropdown
          categoryId={obj.Details.ProductCategoryID}
          onCategoryIdChanged={(e: number) => { handleIdSelection('ProductCategoryID', e) }}
          allowCreateNew={true}
        />
      </div>
      <div className="form-group">
        {/* <MyTextArea name="desc" value={obj.details.desc} label="Description" onChange={handleDetailsInput} err={verr} /> */}
        <label>Description</label>
        <textarea value={obj.Details.ProductDescription} name="ProductDescription" rows={3} onChange={handleDetailsInput}
          className={props.errorObject?.path === "details.ProductDescription" ? 'form-control bg-err' : 'form-control'}
        />
        {props.errorObject?.path === "details.desc" && <div className="err">{props.errorObject.message}</div>}
      </div>
      <div className="form-row mt-3">
        {/* <div className="col-md-3 col-6 form-group">
                <label>MRP</label>
                <input type="number" className="form-control" 
                name="price.MaxRetailPrice"
                onChange={e=>formik.setFieldValue("price?.MaxRetailPrice",e)} />
                {formik.touched.price?.MaxRetailPrice && formik.errors.price?.MaxRetailPrice &&
                <div className="err">{formik.errors.price?.MaxRetailPrice}</div>}
              </div> */}
        <div className="col-md-3 col-6 form-group">
          {/* <ControlEx label="Selling Price" err={verr} value={obj.price.SalePricePerUnit} name="SalePricePerUnit" onChange={handlePriceInput}>
                        <MyInput type="number" />
                    </ControlEx> */}
          <label>MRP Price</label>
          <input type="number" name="MaxRetailPrice" value={obj.Price.MaxRetailPrice} onChange={handlePriceInput}
            className={props.errorObject?.path === "price.MaxRetailPrice" ? 'form-control bg-err' : 'form-control'} />
        </div>
        <div className="col-md-3 col-6 form-group">
          <label>Selling Price</label>
          <input type="number" value={obj.Price.SalePricePerUnit} name="SalePricePerUnit" onChange={handlePriceInput}
            className={props.errorObject?.path === "price.SalePricePerUnit" ? 'form-control bg-err' : 'form-control'} />
        </div>
        {/* <div className="col-md-3 col-6 form-group">
                <label>Unit</label>
                <input type="text" className="form-control" />
              </div> */}
      </div>

      {obj.Details.ProductID > 0 &&
        <div className="form-row align-items-center mt-2 mb-4">
          <div className="col-12 h5 mb-2">Product Images</div>
          <ProductImagesEdit saveImagesTrigger={props.saveImagesToggle} product_id={obj.Details.ProductID}></ProductImagesEdit>
        </div>
      }
    </>
  );
}

export default ProductDetailsEdit;