import React, { useEffect, useState } from 'react';
import * as cdb from '../data-services/product-details-data';
import { ML_ProductCategory } from '../models/api-dto';
import ProductCategoryEdit from './product-category/product-category-edit';


const ProductCategories = (props: any) => {
  const [catStat, setCatStat] = useState<Array<any>>([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedCatId, setSelectedCatId] = useState(0);

  useEffect(() => {
    loadAll();
  }, [])

  const loadAll = () => {
    cdb.GetCategoryWithStats().then(res => {
      setCatStat(res.data);
      handleModalClose();
    });
  }

  const handleModalClose = () => setShowModal(false)

  const editCategory = (id :number) => {
    setSelectedCatId(id);
    setShowModal(true);
  }

  return (
    <>
      <ProductCategoryEdit
        showModal={showModal}
        id={selectedCatId}
        onClose={handleModalClose}
        onSaved={(c: ML_ProductCategory) => loadAll()}/>

      <article className="card">
        <header className="card-header border-bottom-0 bg">
          <strong className="d-inline-block mr-3">All Product Categories</strong>
          <span>Total: {catStat.length}</span>
        </header>

        <div className="table-responsive">
          <table className="table table-hover">
            <tbody>
              {catStat &&
                catStat.map((c: any, idx: number) => {
                  return (
                    <tr key={"cat_lst_" + c.PRCTID}>
                      <td>
                        <p className="title mb-0">{c.CategoryName}</p>
                        {c.IsActive === true &&
                          <span className="badge badge-primary-light">Active</span>}

                        {c.IsActive === false &&
                          <span className="badge badge-gray-light">Inactive</span>}
                      </td>
                      <td className="text-center">
                      <span className="text-muted">Items</span>
                        {c.PRD_COUNT > 0 &&
                        <div className="price h6">{c.PRD_COUNT}</div>
                        }
                        {c.PRD_COUNT === 0 &&
                        <div className="price h6">-</div>
                        }
                      </td>
                      
                      <td className="text-right" style={{ width: 250 }}>
                        <button className="btn btn-outline-primary" onClick={() => editCategory(c.PRCTID)}> Edit </button>
                      </td>
                    </tr>)
                })
              }
            </tbody></table>
        </div> {/* table-responsive .end// */}
      </article> {/* order-group.// */}



    </>
  );
}

export default ProductCategories;