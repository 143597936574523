import { SaleOrderLineItemView, SaleOrderView } from "../../models/api-dto"
import { product_images_url } from "../../models/app-url-consts"
import OrderViewHeader from "./order-view-header"

type attributes = {
    items: SaleOrderLineItemView[]
    saleOrder: SaleOrderView,
}

const OrderItemsView = (props: attributes) => {

    const saleItems = () => {
        return props.items.map((i: SaleOrderLineItemView, idx: number) => {
            return (
                <tr key={"odt"+idx}>
                    <td>
                        <img src={product_images_url + i.ProductInfo.Image.FileName} className="img-xs border" />
                    </td>
                    <td>
                        <p className="title mb-0">{i.ProductInfo.Product.ProductName}</p>
                        <var className="price text-muted">{i.ProductInfo.Category.CategoryName}</var>
                    </td>
                    <td className="align-middle">
                        <p className="title mb-0">Qty: {i.LineItem.Qty} x Rs. {i.ProductInfo.Price.SalePricePerUnit}</p>
                    </td>
                    <td className="align-middle text-right"> <var className="price">Rs. {i.LineItem.LineTotalAmount}</var> </td>
                </tr>
            )
        })
    }

    return (<>

        <OrderViewHeader saleOrder={props.saleOrder} />

        <div className="card-body">
            <div className="row">
                <div className="col-md-4">
                    <h6 className="text-muted">Payment</h6>
                    <span className="text-success">
                        {props.saleOrder.SaleOrder.PaymentMode} </span>
                    <p>
                        {/* Subtotal: Rs. {props.saleOrder.SaleOrder.AmountTotalPrice} <br />
                    Shipping fee:  Rs. {props.saleOrder.SaleOrder.DiscountTotal} <br /> */}
                        <span className="b">Total:  Rs. {props.saleOrder.SaleOrder.AmountGrandTotal} </span>
                    </p>
                </div>
                <div className="col-md-4">
                    <h6 className="text-muted">Contact</h6>
                    <p>{props.saleOrder.User.ShowName} <br /> {props.saleOrder.User.UName} <br /> </p>
                </div>
                <div className="col-md-4">
                    <h6 className="text-muted">Shipping address</h6>
                    <p> {props.saleOrder.SaleOrder.DeliveryAddress}</p>
                </div>
            </div>

        </div>
        <div className="table-responsive">
            <table className="table table-hover">
                <tbody>
                    {saleItems()}
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan={3} className="text-right">Grand Total:</td>
                        <td className="text-right font-weight-bold h4 pb-0 mb-0">Rs. {props.saleOrder.SaleOrder.AmountGrandTotal}</td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </>);
}

export default OrderItemsView;