import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import * as AppAct from '../../redux-infra/rdx-actions';
import { mapStateToProps } from '../../redux-infra/rdx-state-mapper';
import * as rdxConsts from '../../redux-infra/rdx-constants';
import * as cdb from '../../data-services/cart-data';
import * as glob from '../../models/app-consts';
import { Link, useHistory } from 'react-router-dom';
import * as utils from '../../models/app-utils';


const CartWidget = (props: any) => {

  const [count, setCount] = useState(0);

  const history = useHistory();
  const appDisp = useDispatch();

  useEffect(() => {
    getCartItemCount();
  }, [props[rdxConsts.APP_CART_STATUS]])

  const getCartItemCount = () => {
    cdb.GetCartItemsCount(utils.GetUserObj().AID).then(res => setCount(res.data));
  }

  const redirectToReviewCart = () => {
    if (history.location.pathname === '/cart')
      document.location.reload();
    else {
      getCartItemCount();
      history.push("/cart");
      appDisp(AppAct.actionCartStatus(0));
    }
  }

  return (<>

    <div className="widget-header  mr-3 cursor-pointer">
      <a onClick={redirectToReviewCart} className="icon icon-sm rounded-circle border"><i className="fa fa-shopping-cart" /></a>
      {count > 0 &&
        <span className="badge badge-pill badge-danger notify">{count}</span>
      }
    </div>

  </>);
}

export default connect(mapStateToProps)(CartWidget);