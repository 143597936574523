import React from 'react';
import { useDispatch } from 'react-redux';
import * as AppAct from '../../redux-infra/rdx-actions';
import * as glob from '../../models/app-consts';
import * as utils from '../../models/app-utils';
import * as cdb from '../../data-services/cart-data';

type attributes = {
    qty :number
    productId : number
}

const ItemQtyButton =(props:attributes) =>{

    const appDisp = useDispatch();

    const addItemToCart = (qty : number) => {
        
        cdb.AddItemToCart(utils.GetUserObj().AID,props.productId,qty).then(res=>{
            appDisp(AppAct.actionCartStatus(qty));
        });
    }

    const add = () => addItemToCart(1);
    const remove = ()=> addItemToCart(-1);

    return(<>
    <div className="input-group input-spinner">
            <div className="input-group-prepend">
              <button onClick={remove} className="btn btn-light" type="button" id="button-plus"> <i className="fa fa-minus" /> </button>
            </div>
            <input type="text" readOnly={true} className="form-control bg-white" value={props.qty} />
            <div className="input-group-append">
              <button onClick={add} className="btn btn-light" type="button" id="button-minus"> <i className="fa fa-plus" /> </button>
            </div>
          </div> {/* input-group.// */}
    </>);
}

export default ItemQtyButton;