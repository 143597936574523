import React, { useEffect, useState } from 'react';
import AppDownloadSection from '../layout/app-download-section';
import AppFooter from '../layout/app-footer';
import AppMainNav from '../layout/app-main-nav';
import BannerSection from '../layout/banner-section';
import NewArrivalsSection from '../layout/new-arrived-section';
import PopularProductsSection from '../layout/popular-products-section';
import RecommendedSection from '../layout/recommended-section';
import WebsiteFeaturesSection from '../layout/website-features-section';
import ProductOneViewList from './products/product-one-view-list';
import * as pdb from '../data-services/product-details-data';
import { ML_ProductCategory } from '../models/api-dto';
import { mapStateToProps } from '../redux-infra/rdx-state-mapper';
import { connect, useDispatch } from 'react-redux';
import * as AppAct from '../redux-infra/rdx-actions';

const Home = (props: any) => {

  const [productDetailsList, setProductDetailsList] = useState(new Array<any>());
  const [title, setTitle] = useState('All Products');

  const appDisp = useDispatch();

  // useEffect(() => {
  //   loadProductsAll();
  // }, []);

  // const loadProductsAll = () => {
  //   document.body.click();
  //   pdb.GetProductDetailsList().then(res => {
  //     appDisp(AppAct.actionProductsList(res.data));
  //     appDisp(AppAct.actionProductsListTitle('All Products'));
  //     //setProductDetailsList(res.data);
  //     //setTitle('All Products');
  //   })
  // }

  // const loadProductsByCategory = (cat: ML_ProductCategory) => {
  //   document.body.click();
  //   if (cat.PRCTID === 0)
  //     loadProductsAll();
  //   else
  //     pdb.GetProductDetailsListByCategoryId(cat.PRCTID).then(res => {
  //       //setProductDetailsList(res.data)
  //       //setTitle(cat.CategoryName);
  //       appDisp(AppAct.actionProductsList(res.data));
  //       appDisp(AppAct.actionProductsListTitle(cat.CategoryName));
  //     });
  // }

  return (
    <>
      <AppMainNav></AppMainNav>
      {/* section-header.// */}
      {/* ========================= SECTION INTRO ========================= */}
      {/* <BannerSection></BannerSection> */}
      {/* ========================= SECTION INTRO END// ========================= */}
      {/* ========================= SECTION FEATURE ========================= */}
      {/* <WebsiteFeaturesSection></WebsiteFeaturesSection> */}
      {/* ========================= SECTION FEATURE END// ========================= */}
      {/* ========================= SECTION CONTENT ========================= */}

      {/* ========================= SECTION CONTENT END// ========================= */}
      {/* ========================= SECTION CONTENT ========================= */}
      {/* <NewArrivalsSection></NewArrivalsSection> */}

      <ProductOneViewList/>

      {/* ========================= SECTION CONTENT END// ========================= */}
      {/* ========================= SECTION CONTENT ========================= */}
      {/* <RecommendedSection></RecommendedSection> */}
      {/* ========================= SECTION CONTENT END// ========================= */}
      {/* ========================= SECTION  ========================= */}
      {/* <PopularProductsSection></PopularProductsSection> */}
      {/* ========================= SECTION  END// ========================= */}
      {/* ========================= SECTION  ========================= */}
      {/* <AppDownloadSection></AppDownloadSection> */}
    </>
  );
}

export default connect(mapStateToProps)(Home);