import React from 'react';
import { BrowserRouter as Router, Link, matchPath, Route, Switch, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import UserProfile from '../users/user-profile';
import Orders from './orders-admin/orders';
import OrdersAdminView from './orders-admin/orders-admin-view';
import OrdersArchive from './orders-admin/orders-archive';
import ProductCategories from './product-categories';
import ProductsList from './products-listing';

const AdminMainPage = (props: any) => {
    const { url, path } = useRouteMatch();
    const location = useLocation();

    return (
        <>
            <section className="py-3 bg">
                <div className="container">
                    <h2 className="title-page">My Store Settings</h2>
                </div>
            </section>
            <section className="section-content padding-y">
                <div className="container">
                    <div className="row">
                        <aside className="col-md-3">
                            <ul className="list-group">
                                <Link to={`${url}/products`} className={`list-group-item ${location.pathname.endsWith('products') ? 'active' : ''}`}>Products List</Link>
                                <Link to={`${url}/categories`} className={`list-group-item ${location.pathname.endsWith('categories') ? 'active' : ''}`}>Categories</Link>
                                <Link to={`${url}/orders`} className={`list-group-item ${location.pathname.endsWith('orders') ? 'active' : ''}`}>Received orders</Link>
                                <Link to={`${url}/orders-all`} className={`list-group-item ${location.pathname.endsWith('orders-all') ? 'active' : ''}`}>Orders Archive</Link>
                                <Link to={`${url}/profile`} className={`list-group-item ${location.pathname.endsWith('profile') ? 'active' : ''}`}>Account Settings</Link>

                            </ul>
                        </aside>
                        <main className="col-md-9">
                            <Switch>
                                <Route path={path + '/products'}><ProductsList /></Route>
                                {/* <Route path={path+ '/product-details'}><ProductConfig/></Route>  */}
                                <Route path={path + '/categories'}><ProductCategories /></Route>
                                <Route path={path + '/orders'}><Orders /></Route>
                                <Route path={path + '/orders-all'}><OrdersArchive /></Route>
                                <Route path={path + '/profile'}><UserProfile /> </Route>

                            </Switch>
                        </main>
                    </div>
                </div>
            </section>

        </>

    );
}

export default AdminMainPage;