type attributes = {
    status: number
}

const OrderTrackingMap = (props: attributes) => {
    return (<>

        {props.status === 0 &&
            <div className="text-danger font-weight-bold p-2">
                <i className="fa fa-times-circle"></i> Order Canceled
        </div>}

        {props.status > 0 &&
            <div className="tracking-wrap">
                <div className={`step ${props.status >= 1 ? 'active' : ''}`}>
                    <span className="icon"> <i className="fa fa-cart-arrow-down"></i> </span>
                    <span className="text">Order Placed</span>
                </div> {/* step.// */}
                <div className={`step ${props.status >= 2 ? 'active' : ''}`}>
                    <span className="icon"> <i className="fa fa-check"></i>  </span>
                    <span className="text"> Order Accepted</span>
                </div> {/* step.// */}
                <div className={`step ${props.status >= 3 ? 'active' : ''}`}>
                    <span className="icon"> <i className="fa fa-people-carry"></i> </span>
                    <span className="text"> Packing </span>
                </div> {/* step.// */}
                <div className={`step ${props.status >= 4 ? 'active' : ''}`}>
                    <span className="icon"> <i className="fa fa-shipping-fast"></i> </span>
                    <span className="text">Shipping</span>
                </div> {/* step.// */}
                <div className={`step ${props.status >= 5 ? 'active' : ''}`}>
                    <span className="icon"> <i className="fa fa-thumbs-up"></i> </span>
                    <span className="text"> Delivered</span>
                </div> {/* step.// */}
            </div>
        }

    </>);
}

export default OrderTrackingMap;