import { useState } from "react";
import * as udb from '../data-services/users-data';
import { toast } from 'react-toastify';
import * as utils from '../models/app-utils';
import * as AppAct from '../redux-infra/rdx-actions';
import { useDispatch } from "react-redux";
import * as glob from '../models/app-consts';
import { Link, useHistory } from "react-router-dom";

const Login = (props: any) => {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const appDisp = useDispatch();
    const history = useHistory();

    const login = ()=> {
        if(!username || !password){
            toast.error("Enter username and password");
        } else {
            udb.Login(username,password).then(res=>{
                if(!res.data)
                    toast.error("Either username or password is wrong!");
                else{
                    utils.cacheSetUser(res.data);
                    appDisp(AppAct.actionUserStatus(res.data.AID));
                    appDisp(AppAct.actionCartStatus(res.data.AID));
                    history.push('/store');
                }
            });
        }
    }

    const onEnter = (e:any) => {
        if(e.key==='Enter')
            login();
    }

    return (<>
        <div className="card mx-auto" style={{ maxWidth: '380px', marginTop: '50px', marginBottom:'50px' }}>
            <div className="card-body">
                <h4 className="card-title mb-4">Sign in</h4>
                
                    <div className="form-group">
                        <label>Mobile Number</label>
                        <input autoFocus={true} name="uname" className="form-control" type="text" onKeyPress={onEnter} onChange={(e:any)=>setUsername(e.target.value)} />
                    </div> {/* form-group// */}
                    <div className="form-group">
                        <label>Password</label>
                        <input name="pass" className="form-control" type="password" onKeyPress={onEnter} onChange={(e:any)=>setPassword(e.target.value)}/>
                    </div> {/* form-group// */}
                    <div className="form-group">
                        {/* <a href="#" className="float-right">Forgot password?</a> */}
                        <label className="float-left custom-control custom-checkbox"> <input type="checkbox" className="custom-control-input" defaultChecked /> <div className="custom-control-label"> Remember </div> </label>
                    </div> {/* form-group form-check .// */}
                    <div className="form-group">
                        <button onClick={login} type="button" className="btn btn-primary btn-block"> Login</button>
                    </div> {/* form-group// */}
                
            </div> {/* card-body.// */}
            <div className="card-footer text-center">Don't have an account? &nbsp;
                <Link to={'/signup'} className="font-weight-bold">Register for free!</Link>
            </div>   
        </div>

    </>);
}

export default Login;