import { useEffect, useState } from 'react';
import * as yup from 'yup';
import { QL_User } from '../models/api-dto';
import { toast } from 'react-toastify';
import * as udb from '../data-services/users-data';
import { Link } from 'react-router-dom';
import * as glob from '../models/app-consts';


const UserSignup = (props: any) => {

    const[user, setUser] = useState<QL_User>(new QL_User());
    const[inputErr, setInputErr] = useState<any>({});

    useEffect(()=>{
        setUser({...user,  "Active": 1 , "UserType" : glob.ONLINE_CUSTOMER_USER_TYPE});
    },[])

    let validator = yup.object({
            "UName" : yup.string().required("Mobile number required").max(10,"Should be 10 digit number"),
            "PWord" : yup.string().required("Password required"),
            "ShowName" : yup.string().required("Full name required")
    });

    const handleInput = (e:any)=> {
        let name = e.target.name;
        let val = e.target.value;

        setUser({...user,[name]:val});
    }

    const saveUser = ()=> {
        setInputErr({});
        validator.validate(user).then(res=>{
            udb.SaveUser(user).then(res=>{
                toast.success('Thanks for registering');
            })
        }).catch(err=>{
            setInputErr(err);
            toast.error(err.errors[0]);
        });
    }

    

    return (<>
        <div className="card mx-auto mb-5" style={{ maxWidth: '520px', marginTop: '40px' }}>
            <article className="card-body">
                <header className="mb-4"><h4 className="card-title">Sign up</h4></header>
                    <div className="form-row">
                        <div className="col form-group">
                            <label>Full Name</label>
                            <input type="text" className={`form-control ${inputErr.path==='ShowName' ? 'bg-err' :'' }`} 
                            name="ShowName" value={user.ShowName} onChange={handleInput} />
                        </div> {/* form-group end.// */}
                    </div> {/* form-row end.// */}

                    <div className="form-group">
                        <label>Mobile Phone</label>
                        <input type="text" className={`form-control ${inputErr.path==='UName' ? 'bg-err' :'' }`}
                         name = "UName" value={user.UName} onChange={handleInput}/>
                        <small className="form-text text-muted">Your phone number will be used as Login name</small>
                    </div> {/* form-group end.// */}

                    <div className="form-row">
                        <div className="form-group col-md-6">
                            <label>Username</label>
                            <input className="form-control" type="text" readOnly={true} value={user.UName}  />
                        </div> {/* form-group end.// */}
                        <div className="form-group col-md-6">
                            <label>Password</label>
                            <input className={`form-control ${inputErr.path==='PWord' ? 'bg-err' :'' }`} type="password" 
                            name="PWord" value={user.PWord} onChange={handleInput}/>
                        </div> {/* form-group end.// */}
                    </div>
                    <div className="form-group">
                        <button type="submit" className="btn btn-primary btn-block" onClick={saveUser}> Register</button>
                    </div> {/* form-group// */}
                    <div className="form-group">
                        <label className="custom-control custom-checkbox"> <input type="checkbox" className="custom-control-input" defaultChecked /> <div className="custom-control-label"> I am agree with <a href="#">terms and contitions</a></div> </label>
                    </div> {/* form-group end.// */}
                    
            </article>{/* card-body.// */}
            <div className="card-footer text-center">Have an account? &nbsp;
                <Link to={'/login'} className="font-weight-bold">Log In</Link>
            </div>   
        </div>


    </>)
}

export default UserSignup;