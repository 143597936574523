import React from 'react';
import logo from './logo.svg';
import './App.css';
import Header from './layout/app-header';
import AdminMainPage from './admin-settings/main-page';


import AppMainNav from './layout/app-main-nav';
import BannerSection from './layout/banner-section';
import WebsiteFeaturesSection from './layout/website-features-section';
import AppFooter from './layout/app-footer';
import AppDownloadSection from './layout/app-download-section';
import PopularProductsSection from './layout/popular-products-section';
import RecommendedSection from './layout/recommended-section';
import NewArrivalsSection from './layout/new-arrived-section';
import Home from './store/home';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import ProductsByCategory from './store/products-by-categoryp';
import ProductDetails from './store/product-details';
import ShoppingCart from './store/shopping-cart';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ProductOneBigImageView from './store/products/product-one-big-image-view';
import ProductPurchaseView from './store/products/product-purchase-view';
import UserProfile from './users/user-profile';
import UserSignup from './users/user-signup';
import Login from './users/login';
import OrderConfirm from './store/sales/order-confirm';

function App() {
  return (
    <BrowserRouter>
    <ToastContainer style={{height:50}} autoClose={3000} hideProgressBar={true} />
      <>
        <Header></Header>
        <Switch>
          <Route path={'/store'} component={Home} />
          <Route exact path={'/product/:id'} component={ProductPurchaseView} />
          <Route path={'/backstore'}> <AdminMainPage></AdminMainPage> </Route>
          <Route path={'/prod-by-cat'}> <ProductsByCategory></ProductsByCategory> </Route>
          <Route path={'/product-details'}> <ProductDetails></ProductDetails> </Route>
          <Route path={'/cart'}> <ShoppingCart></ShoppingCart> </Route>
          <Route path={'/signup'}><UserSignup/> </Route>
          <Route path={'/login'}><Login/> </Route>
          <Route path={'/order-confirm'}> <OrderConfirm/> </Route>
          
        </Switch>
        {/* ========================= SECTION  END// ======================= */}
        <AppFooter></AppFooter>
      </>

    </BrowserRouter>
  );
}

export default App;
