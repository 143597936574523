const UserAddresses = (props: any) => {
    return (<>

        <div className="card">
            <article className="card-body">
                <header className="mb-4">
                    <h4 className="card-title">Delivery Address</h4>
                    {/* <button className="btn btn-outline-primary">+ Add New</button> */}
                </header>
                    <div className="form-group form-row">
                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-body">
                                    <h6>Pavan Kumar G R</h6>
                                    115, 8th Block, 4th Cross<br/>
                                    Near M.K.S School <br/>
                                    Bangalore <br/>
                                </div>
                                <div className="card-footer text-right">
                                    <button className="btn btn-outline-primary">Edit</button>
                                </div>
                            </div>
                            
                        </div>
                    </div>
            </article> 
        </div>

    </>);
}

export default UserAddresses;