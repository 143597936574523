import * as glob from '../../models/app-consts';
import * as cdb from '../../data-services/cart-data';
import * as AppAct from '../../redux-infra/rdx-actions';
import { useDispatch } from 'react-redux';
import * as utils from '../../models/app-utils';
import { toast } from 'react-toastify';

const AddToCartButton = (props: any) => {
    const appDisp = useDispatch();

    const addItemToCart = () => {

        if (utils.IsUserLoggedIn()) {
            cdb.AddItemToCart(utils.GetUserObj().AID, props.productId, props.currentQty).then(res => {
                appDisp(AppAct.actionCartStatus(props.currentQty));
            });
        } else {
            toast.error("You must login to perform this action.");
        }
    }

    return <button className="btn btn-outline-primary" onClick={() => { addItemToCart() }}>
        <span className="text">Add to cart</span> <i className="fas fa-shopping-cart" />
    </button>
}

export default AddToCartButton;