import React, { useEffect, useState } from 'react';
import * as pdb from '../../data-services/products-data';
import { ML_Product, ProductView } from '../../models/api-dto';

type attributes = {
    product_id : number,
    onSelect(arg : number) : void,
    disabled : boolean
}

const ProductDropDown = (props:attributes) => {

    const [products, setProducts] = useState<ProductView[]>(new Array<ProductView>());

    useEffect(()=>{
        loadAll();
    },[]);

    const loadAll = () => {
        pdb.GetAllProducts().then(res=>{
            setProducts(res.data);
        });
    }

    return (<>
        <select className="form-control" disabled={props.disabled}
        onChange={(e:any)=>props.onSelect(+e.target.value)} 
        value={props.product_id}>
            <option key="pd_0" value={0}> -- NONE --</option>
            {products && products.map((x:ProductView, idx:number) =>{
                return <option key={"pd_"+idx} value={x.Product.PRDID}>
                        {x.Product.ProductName} - 1 {x.UnitMeasure.BulkMeasureName} of {x.UnitMeasure.RetailQty} {x.UnitMeasure.RetailMesaureName}
                    </option>
            })}
        </select>
    </>);
}

export default ProductDropDown;