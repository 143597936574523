import React from 'react';
import { useDispatch } from 'react-redux';
import * as cdb from '../../data-services/cart-data';
import * as AppAct from '../../redux-infra/rdx-actions';
import * as utils from '../../models/app-utils';


type attributes = {
    productId :number
}

const ItemRemoveButton = (props:attributes) => {

    let appDisp = useDispatch();

    const removeItem = ()=> {
        cdb.DeleteItem(utils.GetUserObj().AID,props.productId).then(res=>{
            appDisp(AppAct.actionCartStatus(-1));
        })
    }

    return(<>
        <div className="col flex-grow-0 text-right">
          <button onClick={removeItem} className="btn btn-light"> <i className="fa fa-times text-danger" /> </button>
        </div>
    </>);

}

export default ItemRemoveButton;