    export class QS_ProductDetail {
        
        public PDID: number = 0;
        public ProductID: number = 0;
        public ProductCategoryID: number = 0;
        public ProductPriceID: number = 0;
        public ProductDescription: string = '';
        public ImageFileNamesCSV: string = '';
    }

    export class QS_ProductImage {
        
        public FLID: number = 0;
        public ProductID: number = 0;
        public FileType: string = '';
        public FileName: string = '';
    }

    export class QS_ProductParameter {
        
        public PPMID: number = 0;
        public ProductID: number = 0;
        public ProductDetailsID: number = 0;
        public AttributeName: string = '';
        public AttributeValue: string = '';
    }
         
    export class QS_UserCart {
            
        public CRTID: number = 0;
        public UserID: number = 0;
        public ProductID: number = 0;
        public Qty: number = 0;
        public DateAdded: Date = new Date(0);
    }
    
    
    export class CartView  {
            
        public Product: ProductView = new ProductView();
        public CartItem: QS_UserCart = new QS_UserCart();
        public CurrentPrice: ML_ProductPrice = new ML_ProductPrice();
        public Image: QS_ProductImage = new QS_ProductImage();
    }
    

    export class QS_UserAddress {
        
        public ADID: number = 0;
        public UserID: number = 0;
        public CityName: string = '';
        public StateName: string = '';
        public CountryName: string = '';
        public FullAddress: string = '';
        public IsPrimaryAddress: boolean = false;
    }

    export class QS_UserDetails {
        
        public UDID: number = 0;
        public UserID: number = 0;
        public EmailAddress: string = '';
        public PhoneNumber: string = '';
    }

         
    export class QL_User {
        
        public AID: number = 0;
        public UName: string = '';
        public PWord: string = '';
        public CreationStamp: Date = new Date(0);
        public Active: number = 0;
        public UserType: number = 0;
        public LastLoginStamp: Date = new Date(0);
        public ShowName: string = '';
    }


    export class UserVM {
        
        public User: QL_User = new QL_User();
        public Details: QS_UserDetails = new QS_UserDetails();
        public UserAddresses: QS_UserAddress[] = [];
    }


     
    export class QS_SaleOrderLineItem {
        
        public SLTID: number = 0;
        public SaleOrderID: number = 0;
        public ProductID: number = 0;
        public PriceAmount: number = 0;
        public Qty: number = 0;
        public TaxAmount: number = 0;
        public LineTotalAmount: number = 0;
        public DiscountAmount: number = 0;
        public ShippingAmount: number = 0;
        public DiscountPercentage: number = 0;
    }

    export class QS_SaleOrder {
        
        public SOID: number = 0;
        public UserID: number = 0;
        public OrderDate: Date = new Date(0);
        public OrderCompletedDate: Date = new Date(0);
        public OrderNumber: string = '';
        public DeliveryAddress: string = '';
        public AmountTotalPrice: number = 0;
        public AmountTotalTax: number = 0;
        public AmountGrandTotal: number = 0;
        public AmountPayable: number = 0;
        public DiscountTotal: number = 0;
        public DiscountPercentage: number = 0;
        public ShippingChargeTotal: number = 0;
        public OrderStatus: number = 0;
        public IsPaid: number = 0;
        public PaymentMode: string = '';
    }
    
 
    
    
    export class ML_Customer {
        
        public CID: number = 0;
        public CompanyName: string = '';
        public GSTN: string = '';
        public PAN: string = '';
        public AddressOne: string = '';
        public AddressTwo: string = '';
        public GeoState: string = '';
        public GeoCity: string = '';
        public GeoBusinessCode: string = '';
        public VATNo: string = '';
        public CSTNo: string = '';
        public CompanyPAN: string = '';
        public PrimaryContactPersonName: string = '';
        public PrimaryPhones: string = '';
        public PrimaryEmailAddress: string = '';
        public DateCreated: Date = new Date(0);
        public LastUpdated: Date = new Date(0);
        public CustomerNotes: string = '';
        public KTNumber: string = '';
        public IsAdminOnly: boolean = false;
        public AliasVersions: string = '';
        public PartyType: number = 0;
    }

     
    export class ProductDetailsView  {
        
        public Price: ML_ProductPrice = new ML_ProductPrice();
        public Details: QS_ProductDetail = new QS_ProductDetail();
        public Product: ML_Product = new ML_Product();
        public Category: ML_ProductCategory = new ML_ProductCategory();
        public Image: QS_ProductImage = new QS_ProductImage();
    }
    

    export class SaleOrderLineItemView  {
        
        public LineItem: QS_SaleOrderLineItem = new QS_SaleOrderLineItem();
        public ProductInfo: ProductDetailsView = new ProductDetailsView();
    }

    export class SaleOrderView  {
        
        public SaleOrder: QS_SaleOrder = new QS_SaleOrder();
        public Customer: ML_Customer = new ML_Customer();
        public User: QL_User = new QL_User();
    }

    

//----------- FROM Tracker+ ----------------------
export class ML_Product {
        
    public PRDID: number = 0;
    public ProductName: string = '';
    public ProductCategoryID: number = 0;
    public IsInternalProduct: boolean = false;
    public UnitMeasureID: number = 0;
    public IsActive: boolean = false;
    public ProductDescription: string = '';
    public InternalCode: string = '';
    public BarCode: string = '';
    public CanBeSold: boolean = false;
    public CanBePurchased: boolean = false;
    public ProductImageIDs: string = '';
    public InternalNotes: string = '';
    public LogisticWeightKGs: number = 0;
    public LogisticSpaceCubicMeters: number = 0;
    public ApproxManufacturingTimeInDays: number = 0;
    public HasExtraAttributes: boolean = false;
    public CompanyID: number = 0;
    public CreatorUserID: number = 0;
    public CreateDateTime: string = '';
    public UpdaterUserID: number = 0;
    public UpdateDateTime: string = '';
    public HSNSACNumber: string = '';
}


export class ML_UnitMeasure {
        
    public UCID: number = 0;
    public BulkMeasureName: string = '';
    public RetailMesaureName: string = '';
    public RetailQty: number = 0;
    public IsActive: number = 0;
    public RoundingPrecision: number = 0;
    public CompanyID: number = 0;
    public CreatorUserID: number = 0;
    public CreateDateTime: string = '';
    public UpdaterUserID: number = 0;
    public UpdateDateTime: string = '';
}

    
export class ML_ProductCategory {
        
    public PRCTID: number = 0;
    public CategoryName: string = '';
    public ParentID: number = 0;
    public ParentPath: string = '';
    public ParentPathNames: string = '';
    public IsActive: boolean = false;
    public CompanyID: number = 0;
    public CreatorUserID: number = 0;
    public CreateDateTime: Date = new Date();
    public UpdaterUserID: number = 0;
    public UpdateDateTime: Date = new Date();
}


export class ProductView  {
    
    public Product: ML_Product = new ML_Product();
    public Category: ML_ProductCategory =new ML_ProductCategory();
    public UnitMeasure: ML_UnitMeasure = new ML_UnitMeasure();
    public OnHand: number = 0;
}

    
export class ML_ProductPrice {
        
    public PSID: number = 0;
    public ProductID: number = 0;
    public CompanyID: number = 0;
    public SetByUserID: number = 0;
    public SalePricePerUnit: number = 0;
    public MaxRetailPrice: number = 0;
    public LastUpdateDateTime: Date = new Date(0);
}



 
